.pageNotFoundTitle {
    text-align: center;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 80px;
    color: #ed0084;
    margin-top: 100px;
}

.pageNotFoundSubitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #051F59;
    text-align: center;
    margin: 10px 0 150px 0;
    text-transform: uppercase;
}
