.userAccount {
    padding-top: 20px;
    padding-bottom: 50px;
}

.userAccountBody {
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(214, 221, 231, 0.5);
    border-radius: 10px;
    padding: 25px;
    min-height: 400px;
    height: 100%;
    display: table;
    width: 100%;
}

.userAccountLeftPanel {
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(214, 221, 231, 0.5);
    border-radius: 10px;
    padding: 25px;
}

.userAccountBody .table {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
}

.userAccountBody .table tr {
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.userAccountBody .table tbody tr {
    background-color: rgba(230, 232, 238, 0.3);
}

.userAccountBody .table tbody tr:hover {
    background-color: #E6E8EE;
    cursor: pointer;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.userAccountBody .table .thead-light th {
    font-size: 14px;
    background-color: transparent;
    border: none;
    text-align: center;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #4F6699;
}

.userAccountBody table td,
.table .thead-light th {
    vertical-align: middle;
}

.userAccountBody table td {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #051F59;
    text-align: center;
}

.td-outstanding span {
    color: #bdcad8;
}

.authLoader {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}


.authLoginButton {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;
    color: #283377;
    border: 1px solid #BDCAD8;
    width: 100%;
    box-shadow: none !important;
    cursor: pointer;
    text-align: center;
    box-shadow: none;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.myAccountLogInButton,
.myAccountSignUpButton {
    width: calc(50% - 5px);
    font-family: 'Work Sans';
    font-size: 14px;
}

.myAccountLogInButton {
    margin-right: 10px;
    background-color: #ED0084;
    color: #ffffff;
    border-color: #ED0084;
}

.myAccountLogInButton:hover {
    background-color: #ED0084;
    opacity: 0.7;
}

.myAccountSignUpButton {
    border-color: #BDCAD8;
    color: #BDCAD8;
}

.authLoginButton:hover {
    background-color: #ED0084;
    color: #ffffff;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.noAuthenticatedBlock {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
}

.noAuthenticatedBlockTitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #051F59;
    margin-top: 30px;
}

.noAuthenticatedBlockTitle a {
    color: #ed0885;
    text-decoration: underline;
}

.noAuthenticatedBlockTitle span {
    line-height: 30px;
}

.userInformationBlock {
    text-align: center;
}

.userInformationBlock img {
    width: 100px;
    height: 100px;
    background-color: #BDCAD8;
    border-radius: 50%;
    display: inline-block;
    overflow: hidden;
    color: transparent;
}

.userInformationName {
    font-family: 'Work Sans';
    margin-bottom: 5px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #283377;
    margin-top: 16px;
}

.is-paid,
.no-paid {
    border: 1px solid;
    border-radius: 6px;
    padding: 1px 7px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    min-width: 81px;
    display: inline-block;
    text-align: center;
}

.order-status {
    text-align: center;
    border: 1px solid #051F59;
    border-radius: 6px;
    font-family: 'Work Sans';
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    color: #051F59;
    padding: 1px 7px;
    margin-right: 12px;
    min-width: 98px;
    display: inline-block;
}

.order-status:hover {
    background-color: transparent;
}

.is-paid {
    color: #28a745;
    border-color: #28a745;
}

.no-paid {
    color: #ED0084;
    border-color: #ED0084;
}

.editProfileBlock {
    margin: 23px 0;
}

.editProfileBlock button {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    border: none;
    padding: 0;
    color: #4F6699;
}

.editProfileBlock button:hover {
    background-color: transparent;
    opacity: 0.7;
}


.userInformationElement {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #4F6699;
    /*padding: 25px 0;*/
    padding: 15px 0;
    border-top: 1px solid #D7DCE3;
}

.userInformationElement span {
    display: block;
    font-size: 20px;
    color: #00D3E4;
    margin-bottom: 5px;
}

.userInformationElement .icon-mail {
    font-size: 16px;
}

.authLogOutButton {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    border: none;
    color: #ED0084;
    text-align: center;
    cursor: pointer;
    display: block;
    margin: 50px auto 0 auto;
    padding: 0;
}

.authLogOutButton:hover {
    background-color: transparent;
    opacity: 0.7;
}

.td-name {
    /*max-width: 150px;*/
    color: #bdcad8;
    font-size: 13px;
    line-height: 14px;
}

.td-status {
    min-width: 215px;
}

.td-id {
    max-width: 200px;
}

.isUserAuthLoaderContainer {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #4F6699;
    text-align: center;
    position: relative;
    min-height: 400px;
}

.isUserAuthLoaderContainer span {
    margin-bottom: 15px;
    display: block;
    /*width: 210px;*/
}

.userInformationBody {
    min-height: 284px;
}










@media screen and (min-width: 992px) {


    .userAccount .col-3 {
        -ms-flex: 0 0 300px;
        flex: 0 0 300px;
        max-width: 300px;
    }

    .userAccount .col-9 {
        -ms-flex: 0 0 calc(100% - 300px);
        flex: 0 0 calc(100% - 300px);
        max-width: calc(100% - 300px);
    }

}







@media screen and (max-width: 992px) {

    .userAccount .col-3,
    .userAccount .col-9 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .userAccount .col-3 {
        margin-bottom: 25px;
    }

}

@media screen and (max-width: 768px) {

    .userInformationBlock img {
        width: 100px;
    }

    .authLogOutButton {
        margin-top: 25px;
    }

    .td-name {
         max-width: initial;
    }

    .responsiveTable tbody tr {
        border: 1px solid #d3dbe5;
        margin-bottom: 23px;
        background: #FFFFFF;
        box-shadow: 0px 0px 50px rgba(214, 221, 231, 0.5);
        border-radius: 10px;
        padding: 10px;
    }

    .userAccountBody {
        box-shadow: none;
        background-color: transparent;
        padding: 0px;
    }

    .userAccountBody {
        min-height: 200px;
    }

    .userAccountBreadcrumbs {
        margin-top: -67px;
    }

    .userAccountBreadcrumbs .deliveringBreadcrumbsName {
        z-index: 99;
        position: relative;
        text-align: center;
        width: 100%;
        margin-right: 0;
        float: inherit;
    }

    .userAccountBreadcrumbs .backToCartButton {
        text-align: center;
        width: 100%;
        display: block;
        margin-top: 35px;
    }

    .userAccountBody .table tbody tr {
        background-color: #ffffff;
    }

    .td-status {
        min-width: initial;
    }

    .order-status {
        margin-bottom: 10px;
    }

    .userAccountBody .table tr {
        border: 1px solid #d3dbe5;
    }

    .td-id {
         max-width: initial;
    }

    .noAuthenticatedBlock {
        background: #FFFFFF;
        box-shadow: 0px 0px 50px rgba(214, 221, 231, 0.5);
        border-radius: 10px;
        padding: 50px 25px;
    }

}
