.stepThankYouForSubmitting {
    height: calc(100vh - 120px);
    display: table;
    table-layout: fixed;
    width: 100%;
}

.stepThankYouForSubmitting .container {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
}

.thankImageBlock {
    text-align: center;
    margin-top: -25px;
}

.thankTitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    text-transform: uppercase;
    color: #F00084;
    margin-bottom: 23px;
    margin-top: 42px;
}

.thankDescription {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #263D6E;
    max-width: 570px;
    margin: 0 auto;
}

.thankBackToHomeBlock {
    text-align: center;
    margin: 20px 0 50px 0;
}

.thankBackToHome {
    background-color: #F00084;
    border-radius: 10px;
    width: 250px;
    height: 50px;
    text-align: center;
    color: #ffffff;
    border: none;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 50px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    position: relative;
    padding-right: 35px;
    display: inline-block;
}

.thankBackToHome:hover {
    background-color: #051F59;
    color: #ffffff;
    text-decoration: none;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.thankBackToHome span {
    right: 18px;
    position: absolute;
    top: 50%;
    font-size: 21px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.thankContactsTitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #F00084;
    margin-bottom: 18px;
}

.thankContactsButtonBlocks {
    text-align: center;
    margin-bottom: 20px;
}

.thankContactsButtonBlocks a {
    width: 270px;
    height: 69px;
    display: inline-block;
    background: #FFFFFF;
    box-shadow: 0px 0px 50px 0 #D6DDE7;
    position: relative;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #263D6E;
    overflow: hidden;
    line-height: 69px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.thankContactsButtonBlocks a[name="phone"] {
    margin-right: 30px;
    line-height: normal;
    padding-top: 15px;
}

.thankContactsButtonBlocks a[name="mail"] {
    padding-left: 35px;
}

.thankContactsButtonBlocks a[name="phone"] p {
    font-size: 12px;
    line-height: 14px;
    color: #F1118C;
    margin-top: 2px;
}

.thankContactsButtonBlocks a span {
    position: absolute;
    top: 50%;
    left: 15px;
    color: #F00084;
    font-size: 18px;
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.thankContactsButtonBlocks a:hover {
    text-decoration: none;
    box-shadow: 0px 0px 50px 20px #D6DDE7;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}













@media screen and (max-height: 830px) {

    .thankImageBlock img {
        width: 350px;
    }

}


@media screen and (max-height: 740px) {

    .thankImageBlock img {
        width: 300px;
    }

}

@media screen and (max-height: 700px) {

    .thankTitle {
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .thankBackToHomeBlock {
        margin: 20px 0 30px 0;
    }

    .thankContactsButtonBlocks a {
        height: 50px;
        line-height: 50px;
    }

    .thankContactsButtonBlocks a[name="phone"] {
        padding-top: 7px;
    }

    .thankImageBlock img {
        width: 245px;
    }

}

@media screen and (max-width: 460px) {

    .stepThankYouForSubmitting {
        padding-top: 15px;
    }

    .thankImageBlock img {
        width: 280px;
    }

    .thankTitle {
        margin-top: 0;
        font-size: 18px;
        line-height: 21px;
    }

    .thankDescription {
        font-size: 14px;
        line-height: 21px;
    }

    .thankContactsButtonBlocks a {
        margin-right: 0 !important;
        width: 100%;
        max-width: 300px;
    }

    .thankContactsButtonBlocks a .icon-phone {
        font-size: 22px;
        top: 52%;
    }

}

