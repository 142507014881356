.setupPageBody {
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(214, 221, 231, 0.5);
    border-radius: 10px;
    padding: 40px 35px 28px 35px;
    max-width: 570px;
    margin: 43px auto 60px auto;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #051F59;
}

.setupPageTitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 21px;
    color: #051F59;
    text-align: center;
    margin-bottom: 16px;
}

.setupPageSubTitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #051F59;
    max-width: 331px;
    margin: 0 auto;
}

.setupPageForm {
    padding-top: 40px;
    text-align: center;
    border-top: 1px solid #F1F4F8;
    margin-top: 20px;
}

.setupFormNonRequiredInputsContainer {
    background-color: #F7F9FB;
    padding: 22px 0;
    margin-top: -10px;
}

.setupFormNonRequiredInputsBody {
    /*max-width: 433px;*/
    /*margin: 0 auto;*/
}

.setupPageFormRow {
    /*display: inline-block;*/
    display: block;
    max-width: 330px;
    margin: 0 auto 35px auto;
}

.setupPageFormRowCol-6:first-child {
    margin-right: 20px;
}

.setupPageFormRowCol-6 {
    width: calc(50% - 10px);
    display: inline-block;
}

.setupFormNonRequiredInputsContainer .setupPageFormRow:last-child {
    margin-bottom: 0;
}

.setupPageFormLabel {
    /*display: inline-block;*/
    /*width: 180px;*/
    /*text-align: right;*/
    /*padding-right: 28px;*/
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #4F6699;

    width: 100%;
    text-align: left;
    display: block;
    padding-right: 0;
}

.setupPageFormLabel.read-only {
    color: #A5B0BC;
}

.setupPageFormInput {
    /*display: inline-block;*/
    /*width: 252px;*/
    width: 100%;
    position: relative;
}

.setupPageFormInput input,
.setupPageFormInput textarea {
    width: 100%;
    height: 30px;
    border-bottom: 1px solid #B5B5B5;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #4F6699;
    padding-left: 10px;
    background-color: transparent;
}

.setupPageFormInput input:focus {
    border-color: #4F6699;
}

.read-only-input input:focus {
    border-color: #B5B5B5 !important;
}

.setupPageFormInput textarea {
    height: auto;
    border: none;
    /*padding-bottom: 10px;*/
    border-bottom: 1px solid #B5B5B5;
    position: relative;
    top: 20px;
}

.read-only-input input {
  color: #A5B0BC;
    /*pointer-events: none;*/
}

.setupPageFormInput input::-webkit-input-placeholder,
.setupPageFormInput textarea::-webkit-input-placeholder { /* Edge */
    color: #BDCAD8;
}

.setupPageFormInput input:-ms-input-placeholder,
.setupPageFormInput textarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #BDCAD8;
}

.setupPageFormInput input::placeholder,
.setupPageFormInput textarea::placeholder{
    color: #BDCAD8;
}

.setupPageFormButtonContainer {
    text-align: right;
    max-width: 330px;
    margin: 21px auto 0 auto;
}

.setupPageFormButtonContainer button {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    background-color: #EF0086;
    width: 194px;
    height: 38px;
    border: none;
    border-radius: 0;
    position: relative;
    padding-right: 40px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.setupPageFormButtonContainer button:hover {
    background-color: #051F59;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;

}

.setupPageFormButtonContainer button span {
    font-size: 22px;
    position: absolute;
    top: 50%;
    right: 8px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

/* password input style */
.password {
    display: block;
    position: relative;
    text-transform: uppercase;
    font-weight: 700;
    width: 100%;
    color: #727272;
}
.password__input {
    display: block;
    text-transform: none;
    width: 100%;
    height: 42px;
    border-width: 0 0 1px;
    border-style: solid;
    border-color: #B6B6B6;
    font-weight: 400;
    color: #212121;
}

.password__input:focus, .password__input:active {
    border-color: #00BCD4;
    outline: 0;
}

.password__show {
    cursor: pointer;
    position: absolute;
    top: 6px;
    height: 16px;
    line-height: 16px;
    right: 0;
    /*background-color: #A5B0BC;*/
    padding: 0;
    border: none;
    color: #4F6699;
    border-radius: 4px;
    font-weight: 700;
    font-size: .8em;
    opacity: 1;

    /*-webkit-transform: translateY(-50%);*/
    /*-moz-transform: translateY(-50%);*/
    /*-ms-transform: translateY(-50%);*/
    /*-o-transform: translateY(-50%);*/
    /*transform: translateY(-50%);*/

    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.password__show .icon-eye-off {
    font-size: 17px;
}

.password__show .icon-eye {
    font-size: 13px;
    top: 2px;
    position: relative;
}

.password__show:hover {
    background-color: transparent;
}

.password__input[type="input"] + button {
    opacity: 0.7;
}

.setupPageFormInput.errorInputMessage input,
.setupPageFormInput.wrongFormatMessage input {
    border: none;
    border-bottom: 1px solid #ED0084;
}

.setupPageFormInput.inputContainer .icon-close {
    right: 0;
}

.updateProfileDataButton span {
    font-size: 20px !important;
    right: 14px !important;
}

.cancelEditingProfile {
    border: 1px solid #051F59;
    color: #051F59;
    background-color: transparent;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: inline-block;
    height: 38px;
    line-height: 36px;
    cursor: pointer;
    text-align: center;
    width: calc(100% - 210px);
    float: left;
}

.cancelEditingProfile:hover {
    background-color: #eee;
}

.editProfileButtonsBlock {
    padding: 5px 0 20px 0;
}

.passwordNotValid .wrongFormatTitle {
    text-align: left;
    line-height: 14px !important;
    margin-top: 5px;
    position: relative !important;
}


.passwordInputContainer.wrongFormatMessage .passwordNotValid {
    margin-bottom: -25px;
}
















@media screen and (max-width: 540px) {

    .setupPageFormLabel {
        width: 100%;
        text-align: left;
    }

    .setupPageFormInput,
    .setupPageFormRow {
        width: 100%;
    }

    .setupPageBody {
        padding: 40px 20px 28px 20px;
    }

    .setupPageFormRow {
        padding: 0 20px;
    }

    .passwordNotValid .wrongFormatTitle {
        font-size: 11px;
    }

}
