.stepNavDeliveringTypeContainer {
    padding-left: 0;
}

.headerUserInformationImage {
    display: inline-block;
    width: 35px;
    height: 35px;
    overflow: hidden;
    float: left;
    background: #BDCAD8;
    border-radius: 50%;
}

.headerUserInformationImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    color: transparent;
}

.headerUserInformationBlock {
    margin-top: 9px;
    width: 185px;
    /*overflow: hidden;*/
    float: right;
    text-align: right;
}

.headerUserInformationBlock.not-authenticated {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    width: 100%;
    text-align: right;
    margin-top: 16px;
    overflow: visible;
}

.headerUserInformationBlock.not-authenticated a,
.headerUserInformationBlock.not-authenticated div {
    color: #ED0084;
    text-decoration: none;
}

.desktopNotAuthBlock span {
    margin-left: 0px;
    position: relative;
    top: 1px;
    right: 0;
    font-size: 15px;
    font-weight: bold;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.headerUserInformationBlock.not-authenticated a:hover {
    opacity: 0.7;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.headerUserInformationBlock.not-authenticated a:hover span {
    right: -5px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.headerUserInformationText {
    display: inline-block;
    width: calc(100% - 35px);
    padding-left: 10px;
    text-align: left;
}

.headerUserInformationBody {
    display: inline-block;
}

.headerUserInformationName {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #051F59;
    margin-top: 1px;
    height: 16px;
    margin-bottom: 2px;
    overflow: hidden;
}

.headerLinkToMyOrders {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    color: #ED0084;
}

.headerLinkToMyOrders a {
    color: #ED0084;
    text-decoration: none;
}

.headerLinkToMyOrders span {
    font-size: 9px;
    margin-left: 5px;
    position: relative;
    right: 0;
    font-weight: bold;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.headerLinkToMyOrders a:hover {
    opacity: 0.7;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.headerLinkToMyOrders a:hover span {
    right: -5px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.desktopNotAuthBlock {
    display: block;
}

.mobileNotAuthBlock {
    display: none;
}




@media screen and (max-width: 1200px) {

    .headerUserInformationBlock.not-authenticated {
        font-size: 15px;
    }

}


@media screen and (max-width: 992px) {

    .headerUserInformationText {
        display: none;
    }

    .headerUserInformationBlock {
        width: auto;
    }

    .desktopNotAuthBlock {
        display: none;
    }

    .mobileNotAuthBlock {
        display: block;
        width: 35px;
        height: 35px;
        float: right;
        border-radius: 50%;
        border: 1px solid #becddc;
        color: #becddc;
        position: absolute;
        right: 15px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .mobileNotAuthBlock span {
        position: absolute;
        left: 50%;
        top: calc(50% - 1px);
        z-index: 2;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .mobileNotAuthBlock:hover {
        background-color: #ED0084;
        color: #ffffff;
        opacity: 1;
        border-color: #ED0084;
    }

    .headerUserInformationImage {
        position: absolute;
        right: 15px;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        top: 50%;
    }

    .headerUserInformationBlock a:hover span {
        color: #ffffff;
        right: initial !important;
    }


}


@media screen and (max-width: 768px) {

    .headerUserInformationBlock {
        margin-top: 0;
        /*position: absolute;*/
        /*top: 50%;*/
        /*-webkit-transform: translateY(-50%);*/
        /*-moz-transform: translateY(-50%);*/
        /*-o-transform: translateY(-50%);*/
        /*transform: translateY(-50%);*/
        /*right: 15px;*/
    }

    .headerUserInformationImage,
    .mobileNotAuthBlock {
        width: 30px;
        height: 30px;
    }

}
