@font-face {
  font-family: 'Work Sans';
  src: url('fonts/WorkSans-Black.eot');
  src: url('fonts/WorkSans-Black.eot?#iefix') format('embedded-opentype'),
  url('fonts/WorkSans-Black.woff2') format('woff2'),
  url('fonts/WorkSans-Black.woff') format('woff'),
  url('fonts/WorkSans-Black.ttf') format('truetype'),
  url('fonts/WorkSans-Black.svg#WorkSans-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Work Sans';
  src: url('fonts/WorkSans-Bold.eot');
  src: url('fonts/WorkSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('fonts/WorkSans-Bold.woff2') format('woff2'),
  url('fonts/WorkSans-Bold.woff') format('woff'),
  url('fonts/WorkSans-Bold.ttf') format('truetype'),
  url('fonts/WorkSans-Bold.svg#WorkSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
    font-family: 'Work Sans';
    src: url('fonts/WorkSans-Medium.eot');
    src: url('fonts/WorkSans-Medium.eot?#iefix') format('embedded-opentype'),
    url('fonts/WorkSans-Medium.woff2') format('woff2'),
    url('fonts/WorkSans-Medium.woff') format('woff'),
    url('fonts/WorkSans-Medium.ttf') format('truetype'),
    url('fonts/WorkSans-Medium.svg#WorkSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}


@font-face {
    font-family: 'Work Sans';
    src: url('fonts/WorkSans-Regular.eot');
    src: url('fonts/WorkSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/WorkSans-Regular.woff2') format('woff2'),
    url('fonts/WorkSans-Regular.woff') format('woff'),
    url('fonts/WorkSans-Regular.ttf') format('truetype'),
    url('fonts/WorkSans-Regular.svg#WorkSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?z5f2z9');
  src:  url('fonts/icomoon.eot?z5f2z9#iefix') format('embedded-opentype'),
  url('fonts/icomoon.ttf?z5f2z9') format('truetype'),
  url('fonts/icomoon.woff?z5f2z9') format('woff'),
  url('fonts/icomoon.svg?z5f2z9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}



[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-user:before {
  content: "\e900";
}
.icon-close:before {
  content: "\e901";
}
.icon-cart-big:before {
  content: "\e934";
}
.icon-trash1:before {
  content: "\e93c";
}
.icon-dots:before {
  content: "\e916";
}
.icon-warning:before {
  content: "\e917";
}
.icon-t-shirt:before {
  content: "\e918";
}
.icon-tank-tops:before {
  content: "\e919";
}
.icon-sweatshirt:before {
  content: "\e91a";
}
.icon-select-artwork:before {
  content: "\e91b";
}
.icon-save:before {
  content: "\e91c";
}
.icon-right-big-arrow:before {
  content: "\e91d";
}
.icon-rabbit:before {
  content: "\e91e";
}
.icon-que:before {
  content: "\e91f";
}
.icon-printing:before {
  content: "\e920";
}
.icon-plus:before {
  content: "\e921";
}
.icon-phone:before {
  content: "\e922";
}
.icon-ok:before {
  content: "\e923";
}
.icon-minus:before {
  content: "\e924";
}
.icon-mail:before {
  content: "\e925";
}
.icon-home:before {
  content: "\e926";
}
.icon-hats:before {
  content: "\e927";
}
.icon-fire:before {
  content: "\e928";
}
.icon-file:before {
  content: "\e929";
}
.icon-eye-off:before {
  content: "\e92a";
}
.icon-eye:before {
  content: "\e92b";
}
.icon-embroidery:before {
  content: "\e92c";
}
.icon-edit:before {
  content: "\e92d";
}
.icon-eagle:before {
  content: "\e92e";
}
.icon-dollar:before {
  content: "\e92f";
}
.icon-delivery:before {
  content: "\e930";
}
.icon-copy:before {
  content: "\e931";
}
.icon-close1:before {
  content: "\e932";
}
.icon-cheetah:before {
  content: "\e933";
}
.icon-cart:before {
  content: "\e935";
}
.icon-bags:before {
  content: "\e936";
}
.icon-attach:before {
  content: "\e937";
}
.icon-arrow-right:before {
  content: "\e938";
}
.icon-arrow-for-select:before {
  content: "\e939";
}
.icon-arrow-down:before {
  content: "\e93a";
}
.icon-arrow-big-right:before {
  content: "\e93b";
}












html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5,
h6, p, blockquote, pre, a, abbr, acronym, address, big,
cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

body, html {
  height: 100%;
}

img, fieldset, a img {
  border: none;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  -webkit-appearance: none;
  font-size: 16px;
}

input[type="submit"],
button {
  cursor: pointer;
}
input[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

input, button {
  margin: 0;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
}

div, input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none;
}

ul, ol {
  list-style-type: none;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  * {
    -webkit-text-size-adjust: none;
  }
}
table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

html {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
*:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}






/* CUSTOM SCROLLBAR */

/* width */
html {
  scrollbar-width: thin;
  scrollbar-color: rgb(0, 128, 255) #ffffff;
}


::-webkit-scrollbar {
  width: 10px;
}


/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ED0084;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /*background: #ffffff;*/
}


/* CUSTOM SCROLLBAR */











button {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #337ab7;
  background-color: transparent;
  /*background-color: #337ab7;*/
  border-color: #2e6da4;
  outline: none !important;
}

button:hover {
  background-color: #eee;
}

.active button {
  color: #ffffff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

.stepBody {
  padding-top: 92px;
}

.bigLoader {
  background-color: rgba(5, 31, 89, 0.7);
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.bigLoader > div {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.bigLoader circle {
  stroke: #ffffff;
}

.bigLoader path {
  animation-duration: 0.5s;
}













@media screen and (min-width: 1200px) {

  .container {
    max-width: 1170px;
    padding: 0;
  }

}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  .stepSelectProduct .container {
    max-width: 100%;
    padding: 0 30px;
  }

  .stepEditProduct .container.desktopVersionContainer {
    max-width: 992px;
  }

}

@media screen and (min-width: 992px) {

  .technicalWorksContainer {
    display: none;
  }

}

@media screen and (max-width: 992px) {

  .stepBody .stepEditProduct .container,
  .stepBody .stepCart .container {
    /*display: none;*/
  }

  .technicalWorksContainer {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    /*background-color: #051F59;*/
    /*background-color: #ED0084;*/
  }

  .tech-work-show {
    display: block !important;
  }

  .technicalWorksTextBlock {
    width: 90%;
    padding: 30px;
    border-radius: 10px;
    max-width: 340px;
    height: auto;
    background-color: #FFFFFF;
    position: absolute;
    top: 50%;
    z-index: 1;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .technicalWorksTextBlock a {
    color: #ee0084;
  }

  .technicalWorksTextBlock .icon-arrow-right {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
    display: inline-block;
    font-size: 11px;
    position: relative;
    top: -2px;
    margin-right: 5px;
  }

  .technicalWorksTextBlock h1 {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    text-align: left;
    font-size: 22px;
    color: #051F59;
    margin-bottom: 10px;
  }

  .technicalWorksTextBlock p {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    text-align: left;
    font-size: 16px;
    /*color: #051F59;*/
  }

  .animationBgArea {
    background-color: #051F59;
    position: absolute;
    left: 0;
    top: 0;
    /*background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);*/
    width: 100%;
    height: 100%;
  }

  .animationBgElementsList{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .animationBgElementsList li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: #ED0084;
    animation: technicalWorksContainerAnimate 25s linear infinite;
    bottom: -150px;

  }

  .animationBgElementsList li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }


  .animationBgElementsList li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
  }

  .animationBgElementsList li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
  }

  .animationBgElementsList li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
  }

  .animationBgElementsList li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
  }

  .animationBgElementsList li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
  }

  .animationBgElementsList li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
  }

  .animationBgElementsList li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
  }

  .animationBgElementsList li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
  }

  .animationBgElementsList li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
  }



  @keyframes technicalWorksContainerAnimate {

    0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
    }

    100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
    }

  }

}
