.card-deck {
    width: 100%;
    margin: 0;
}

.stepEditProduct {
    padding-bottom: 50px;
}

.stepSelectProduct {
    padding-bottom: 80px;
}

.stepSelectProduct .card-text {
    height: 45px;
    overflow: hidden;
}

.productList .col-3 {
    padding: 0 10px;
}



.productList {
    padding: 0 5px;
    -ms-flex: 0 0 calc(100% - 87px - 18px);
    flex: 0 0 calc(100% - 87px - 18px);
    max-width: calc(100% - 87px - 18px);
    max-height: 385px;
    overflow: hidden;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    -ms-transition: all 1s;
    transition: all 1s;
}

.productItem:hover {
    cursor: pointer;
}

.productItemImageContainer {
    text-align: center;
    background-color: #D8D8D8;
    -webkit-filter: brightness(1.15);
    filter: brightness(1.15);
    height: 290px;
    position: relative;
}

.productItemImageContainer .icon-fire {
    position: absolute;
    right: 9px;
    top: 7px;
    color: #BDCAD8;
    font-size: 17px;
    -webkit-transition: all .8s;
    -moz-transition: all .8s;
    -o-transition: all .8s;
    -ms-transition: all .8s;
    transition: all .8s;
}

.productItemImageContainer img {
    mix-blend-mode: multiply;
    height: 100%;
    width: auto;
    position: absolute;
    left: 50%;
    top: 0;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

.productItemName {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    margin-top: 10px;
    color: #051F59;
}

.productItemInfo {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    text-align: center;
    margin-bottom: 5px;
    color: #BDCAD8;
}

.dollarVisualizationContainer {
    background-color: #E4EDF6;
    margin-right: 6px;
    display: inline-block;
    width: 32px;
    height: 14px;
    position: relative;
    top: 3px;
}

.dollarVisualizationContainer .colorFillBlock {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #BDCAD8;
    height: 100%;
    width: 100%;
    display: block;
    width: 0;
    transition: all 1s;
}

.dollarVisualizationContainer img {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
}

.productItemButtonSelect {
    background: #00D3E4;
    border-radius: 0px 0px 5px 5px;
    width: 100%;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    border: none;
    opacity: 0;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
    -webkit-transform: translateY(28px);
    -moz-transform: translateY(28px);
    -ms-transform: translateY(28px);
    -o-transform: translateY(28px);
    transform: translateY(28px);
}

.productItem {
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 21px;
}

.productItem:hover .icon-fire {
    color: #ED0084;
    -webkit-transition: all .8s;
    -moz-transition: all .8s;
    -o-transition: all .8s;
    -ms-transition: all .8s;
    transition: all .8s;

    -webkit-animation-name: tada;
    animation-name: tada;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
}

.productItemButtonSelect:hover {
    background-color: #ED0084;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.productItem:hover .productItemButtonSelect {
    opacity: 1;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
}

.productItem:hover {
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
    box-shadow: 0px 0px 20px rgba(189, 202, 216, 0.4);
}

.frontImage {
    /*opacity: 1;*/
    /*-webkit-transition: all .3s;*/
    /*-moz-transition: all .3s;*/
    /*-o-transition: all .3s;*/
    /*-ms-transition: all .3s;*/
    /*transition: all .3s;*/
    display: block;
}

.backImage {
    /*-webkit-transition: all .3s;*/
    /*-moz-transition: all .3s;*/
    /*-o-transition: all .3s;*/
    /*-ms-transition: all .3s;*/
    /*transition: all .3s;*/
    /*opacity: 0;*/
    display: none;
}

.productItem:hover .backImage {
    /*opacity: 1;*/
    /*-webkit-transition: all .3s;*/
    /*-moz-transition: all .3s;*/
    /*-o-transition: all .3s;*/
    /*-ms-transition: all .3s;*/
    /*transition: all .3s;*/
    display: block;
}

.productItem:hover .frontImage {
    display: none;
    /*opacity: 0;*/
    /*-webkit-transition: all .3s;*/
    /*-moz-transition: all .3s;*/
    /*-o-transition: all .3s;*/
    /*-ms-transition: all .3s;*/
    /*transition: all .3s;*/
}

.showAllProductButtonContainer {
    -ms-flex: 0 0 calc(87px + 18px);
    flex: 0 0 calc(87px + 18px);
    max-width: calc(87px + 18px);
    padding: 0;
    height: 290px;
    opacity: 1;
    transition: all 1s;
}

.showAllProductButton {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #ED0084;
    text-align: center;
    margin-left: 18px;
    height: 100%;
    position: relative;
    background-color: #f8f7f8;
    border: 1px solid #ED0084;
    cursor: pointer;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
    overflow: hidden;
}

.showAllProductButton span {
    display: block;
    margin-bottom: 10px;
}

.showAllProductButtonBody {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.showAllProductButton:hover {
    background-color: #ED0084;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.showAllProductButton:hover .showAllProductButtonBody {
    color: #ffffff;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.productCategoryRow {
    margin: 0;
    margin-bottom: 25px;
    clear: both;
}

.productCategoryRowList {
    clear: both;
}

.productCategoryRowContainer {
    padding: 0;
}

.filtersPanel {
    width: 190px;
}

.productCategoryRowTitle {
    padding-bottom: 8px;
    border-bottom: 2px solid #F1F4F8;
    margin-bottom: 8px;
    font-weight: bold;
    position: relative;
    font-family: 'Work Sans';
    font-style: normal;
    color: #051F59;
    font-size: 16px;
    line-height: 19px;
}

.productCategoryHot .productCategoryTitle {
    color: #ED0084;
    text-transform: uppercase;
}

.categoryItemsLengthValue {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px !important;
    margin-left: 8px;
    text-transform: none;
    line-height: 14px;
    color: #BDCAD8;
}

.productCategoryRowTitle .icon-fire {
    display: none;
}

.categoryTitleLoader {
    width: 150px;
    color: transparent;
}

.productCategoryHot .icon-fire {
    display: inline !important;
}

.productCategoryHot .icon-fire {
    margin-right: 8px;
    font-size: 20px;
    top: -1px;
    position: relative;
    float: left;
}

.productCategoryRowHideButton {
    position: absolute;
    right: 0;
    top: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #ED0084;
    cursor: pointer;
}

.productCategoryRowHideButton span {
    font-size: 2px;
    font-weight: normal;
    top: -4px;
    margin-right: 10px;
    position: relative;
}

.productCategoryRowHideButtonHide {
    display: none;
}


/* PRODUCTS LOADING BLOCKS STYLE*/
.loadingAnimationContent:hover {
    box-shadow: none;
}

.loadingAnimationContent .productItemImageContainer {
    background-color: #E9E9E9;
    -webkit-filter: none;
    filter: none;
}

.loadingAnimationContent .productItemName {
    background-color: #E9E9E9;
    height: 16px;
    padding-top: 0;
    width: 80%;
    margin: 10px auto 0 auto;
}

.loadingAnimationContent .productItemInfo {
    height: 15px;
    width: 80px;
    margin: 5px auto 0 auto;
    background-color: #E9E9E9;
}

.showAllProductButtonHidden {
    cursor: not-allowed;
    opacity: 0.2;
}

.showAllProductButtonHidden:hover {
    background-color: transparent;
}

.showAllProductButtonHidden:hover .showAllProductButtonBody {
    color: #ED0084;
}


@-webkit-keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}


.load-content-animation {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    background: #F6F6F6;
    /*background: linear-gradient(to right, #F6F6F6 18%, #F0F0F0 28%, #F6F6F6 43%);*/
    background: linear-gradient(to right, #e9ecef 7%, #F0F0F0 38%, #F6F6F6 39%);
    background-size: 800px 104px;
    position: relative;
}














.showAllProductList + .showAllProductButtonContainer {
    visibility: hidden;
    opacity: 0;
    transition: all 1s;
    -ms-flex: 0 0 0;
    flex: 0 0 0;
    max-width: 0;
}

.showAllProductList {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    -ms-transition: all 1s;
    transition: all 1s;

    height: auto;
    max-height: 10000px
}

.productList .col-3 > a {
    text-decoration: none;
}

.categoryNavigationContainer {
    background-color: #E6E8EE;
    border-radius: 10px;
    padding: 13px 20px;
    margin-bottom: 20px;
    top: 72px !important;
    z-index: 9;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.categoryNavigationContainer ul {
    overflow: hidden;
}

.categoryNavigationContainer ul li {
    float: left;
    /*width: 16.6%;*/
    margin-right: 7%;
    text-align: center;
    font-weight: bold;
    font-family: 'Work Sans';
    font-size: 16px;
    line-height: 19px;
}

.categoryNavigationContainer ul li a {
    color: #051F59;
}

.categoryNavigationContainer ul li:last-child {
    margin-right: 0;
}

.categoryNavigationContainer.is-sticky {
    background-color: #ffffff;
    border-radius: 0;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.is-selected {
    color: #ED0084 !important;
}

.is-selected {
    color: #ffffff;
}

.goToMyAccountPageContainer {
    text-align: right;
    /*margin-bottom: 10px;*/
}

.goToMyAccountPageLink {
    text-align: center;
    width: 100%;
    line-height: 0;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #051F59;
}

.goToMyAccountPageLink .icon-arrow-right {
    font-size: 10px;
    margin-left: 5px;
}

.goToMyAccountPageLink:hover {
    color: #ED0084;
    text-decoration: none;
}

.showAllProductButtonBody .icon-arrow-right {
    display: none;
}

.filterTypeBlock {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 92px;
}

.filterTypeName {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #051F59;
    margin-bottom: 16px;
}

/* custom checkbox style */
.styled-checkbox {
    position: absolute;
    opacity: 0;
}

.styled-checkbox + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #283377;
}

.styled-checkbox + label:before {
    content: '';
    margin-right: 11px;
    display: inline-block;
    vertical-align: text-top;
    width: 12px;
    height: 12px;
    background-color: #BDCAD8;
    border-radius: 3px;

    top: 4px;
    position: relative;
}

.styled-checkbox:hover + label:before {
    background: #ED0084;
}

.styled-checkbox:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.styled-checkbox:checked + label:before {
    background: #ED0084;
}

.styled-checkbox:disabled + label {
    color: #b8b8b8;
    cursor: auto;
}

.styled-checkbox:disabled + label:before {
    box-shadow: none;
    background: #ddd;
}

.styled-checkbox:checked + label:after {
    content: "\e90b";
    font-size: 5.7px;
    color: #ffffff;
    font-family: 'icomoon' !important;
    position: absolute;
    left: 2.4px;
    top: -1px;
    font-weight: 100;
}































@-webkit-keyframes tada {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    10%,
    20% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }

    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }

    40%,
    60%,
    80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes tada {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    10%,
    20% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }

    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }

    40%,
    60%,
    80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}


























@media screen and (max-width: 1200px) {

    .productList {
        max-height: 330px;
    }

    .productItemName {
        font-size: 13px;
    }

    .productItemImageContainer {
        height: 245px;
    }

    .showAllProductList {
        max-height: 10000px;
    }

    .showAllProductButtonContainer {
        height: 245px;
    }

    .categoryNavigationContainer ul li {
        margin-right: 3.8%;
    }

}



@media screen and (max-width: 992px) {

    .productList .col-3 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .productList {
        -ms-flex: 0 0 100% !important;
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }

    .showAllProductButtonContainer {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        height: auto;
    }

    .showAllProductButton {
        width: 139px;
        height: 28px;
        border: 1px solid #F30084;
        box-sizing: border-box;
        border-radius: 14px;
        font-size: 12px;
        background-color: transparent;
        margin: 0 auto;
        line-height: 30px;
    }

    .showAllProductButtonBody .icon-plus {
        display: none;
    }

    .showAllProductButtonBody .icon-arrow-right {
        display: inline-block;
        font-size: 9px;
        margin-left: 10px;
        transform: rotate(90deg);
    }

    .showAllProductButtonHidden {
        display: none;
    }


}


@media screen and (max-width: 767px) {

    .goToMyAccountPageLink a span {
        top: 0.2px;
    }

    .categoryItemsLengthValue {
        float: right;
        top: 8px;
        position: relative;
    }

    .goToMyAccountPageLink {
        color: #BDCAD8;
        text-transform: uppercase;
        padding: 8px;
        font-size: 14px;
        border: 1px solid #BDCAD8;
        display: block;
    }

    .goToMyAccountPageLink:hover {
        color: #ffffff;
        background-color: #ED0084;
    }

    .goToMyAccountPageContainer {
        margin-bottom: 20px;
    }

    .productList .col-3 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .productItemImageContainer {
        height: 150px;
    }

    .productItemButtonSelect {
        display: none;
    }

    .productItemInfo {
        margin-bottom: 0;
    }

    .productItem {
        margin-bottom: 10px;
    }

    .productList {
        max-height: 445px;
    }

    .productCategoryRow[categoty-name="Most popular"] .productList {
        max-height: 222px;
    }

    .showAllProductList {
        max-height: 10000px !important;
    }

    .showAllProductList + .showAllProductButtonContainer {
        display: none;
    }

    .productCategoryRowHideButton {
        display: none;
    }

    .headerContainer {
        padding: 2px 5px;
        border-bottom: none;
    }

    .headerLogoContainer img {
        max-width: 48px;
    }

    .stepNavItemCircle {
        display: none;
    }

    .headerContainer {
        height: 45px;
    }

    .headerContainer .container,
    .headerContainer .row {
        min-height: 100%;
        height: 100%;
    }

    .stepNavigation {
        line-height: 0;
    }

    .stepBody {
        padding-top: 60px;
    }

}
