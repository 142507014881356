.productsImagesScreenshot {
    position: absolute;
    z-index: -999999;
    left: -1000%;
    top: -1000%;
    height: 1px;
    width: 1px;
    overflow: hidden;
    opacity: 0;
}
