.cartProductItem .col-4 {
    -ms-flex: 0 0 300px;
    flex: 0 0 300px;
    max-width: 300px;
}

.cartProductItem .col-8 {
    -ms-flex: 0 0 calc(100% - 300px);
    flex: 0 0 calc(100% - 300px);
    max-width: calc(100% - 300px);
}

.cartProductSizesAmountList {
    padding: 0;
    /*overflow: hidden;*/
}
.orderPriceDeliveringList
.previewProductSliderContainer .image-gallery-left-nav,
.previewProductSliderContainer .image-gallery-right-nav {
    height: 100%;
}

.previewProductSliderContainer .image-gallery-fullscreen-button::before,
.previewProductSliderContainer .image-gallery-play-button::before,
.previewProductSliderContainer .image-gallery-left-nav::before, .image-gallery-right-nav::before {
    font-size: 50px;
}

.previewProductSliderContainer .image-gallery-fullscreen-button:hover::before,
.previewProductSliderContainer .image-gallery-play-button:hover::before,
.previewProductSliderContainer .image-gallery-left-nav:hover::before, .image-gallery-right-nav:hover::before {
    color: #ffffff !important;
}

.previewProductSliderContainer .image-gallery-left-nav:hover,
.previewProductSliderContainer .image-gallery-right-nav:hover {
    background-color: rgba(65, 65, 65, 0.4);
}

.cartDesignItem {
    background: #FFFFFF;
    box-shadow: 0px 0px 50px #D6DDE7;
    padding: 20px 25px 20px 30px;
    margin-bottom: 34px;
}

.artDesignIteArtworkBlock {
    width: 75px;
    margin-right: 30px;
    display: inline-block;
    float: left;
}

.cartProductList {
    display: inline-block;
    width: calc(100% - 75px - 30px);
}

.cartDesignItemName {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #051F59;
    margin-bottom: 10px;
}

.cartDesignItemArtworkList ul li {
    text-align: center;
}

.cartDesignItemArtworkList ul li img {
    width: 70px;
    height: 50px;
    object-fit: contain;
}

.previewProductSliderContainer .image-gallery-slide-wrapper {
    margin-left: 55px;
    width: 215px;
    height: 256px;
}

.previewProductSliderContainer .image-gallery-thumbnails-container {
    transform: initial !important;
}

.previewProductSliderContainer .image-gallery-thumbnails-wrapper {
    position: absolute !important;
    left: 0;
    top: 0;
    height: 100%;
    width: 36px;
}

.previewProductSliderContainer .image-gallery-thumbnails {
    padding: 0;
}

.previewProductSliderContainer .image-gallery-thumbnail {
    display: block !important;
    width: 100% !important;
    margin-bottom: 17px;
    background-color: #D8D8D8;
    filter: brightness(1.15);
}

.previewProductSliderContainer .image-gallery-thumbnail:last-child {
    margin-bottom: 0;
}

.previewProductSliderContainer .image-gallery-thumbnail.active,
.previewProductSliderContainer .image-gallery-thumbnail {
    border: none !important;
}

.previewProductSliderContainer .image-gallery-thumbnail img {
    mix-blend-mode: multiply;
    width: 100% !important;
    height: 36px;
    object-fit: contain;
    border-radius: 2px;
    opacity: 0.5;
    border: 1px solid transparent !important;
}

.previewProductSliderContainer .image-gallery-thumbnail.active img {
    opacity: 1;
    border: 1px solid #C5CCCD !important;
}

.previewProductSliderContainer .image-gallery-thumbnail + .image-gallery-thumbnail {
    margin-left: 0 !important;
}

.previewProductSliderContainer .image-gallery-slide {
    background-color: #D8D8D8 !important;
    filter: brightness(1.15);
}

.previewProductSliderContainer .image-gallery-thumbnails {
    padding: 0 !important;
}

.previewProductSliderContainer .image-gallery-slide img {
    mix-blend-mode: multiply;
    width: 100% !important;
    height: 256px !important;
    object-fit: contain;
}

.previewProductSliderContainer .image-gallery-left-nav,
.previewProductSliderContainer .image-gallery-right-nav {
    border-radius: 0;
}
/*.image-gallery-fullscreen-button::before,*/
/*.image-gallery-play-button::before,*/
.previewProductSliderContainer .image-gallery-left-nav::before,
.previewProductSliderContainer .image-gallery-right-nav::before {
    text-shadow: none !important;
    color: #ddd !important;
    font-size: 35px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.previewProductSliderContainer .image-gallery-left-nav:hover,
.previewProductSliderContainer .image-gallery-right-nav:hover {
    background-color: rgba(65, 65, 65, 0.16);
}

.previewProductSliderContainer .image-gallery-slide-wrapper:hover .image-gallery-left-nav:before,
.previewProductSliderContainer .image-gallery-slide-wrapper:hover .image-gallery-right-nav:before {
    opacity: 1;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}


.cartProductName {
    margin-bottom: 10px;
    width: calc(100% - 125px);
}

.cartProductName h5 {
    display: inline;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #051F59;
    margin-right: 8px;
}

.cartProductName span {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #FFFFFF;
    background-color: #24D4E3;
    border-radius: 3px;
    display: inline-block;
    padding: 2px 10px;
    text-transform: capitalize;
    position: relative;
    top: -1px;
}

.orderProductServicesType {
    text-transform: capitalize;
}

.orderProductServicesType span {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    box-sizing: border-box;
    border-radius: 3px;
    width: 45px;
    display: inline-block;
    text-align: center;
}

.orderProductServicesType span[type="print"] {
    color: #ED0084;
    border: 1px solid #ED0084;
}

.orderProductServicesType span[type="embr"] {
    color: #00D3E8;
    border: 1px solid #00D3E8;
}

.cartProductName.printingType span {
    background-color: #ed0084 !important;
}

.cartProductDescription {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #4F6699;
    margin-bottom: 10px;
    height: 20px;
    overflow: hidden;
}

.cartProductSizesAmountList .productSizeItem {
    margin-right: 2%;
}

.cartProductSizesAmountList .productSizeItem:last-child {
    margin-right: 0;
}

.cartProductSizesAmountList .productSizeItem input,
.cartProductSizesAmountList .productSizeItem span {
    box-shadow: 0px 0px 50px #D6DDE7;
}

.cartProductItemColorPriceBlock {
    padding: 16px 0;
    border-top: 2px solid #F1F4F8;
    margin: 0;
    margin-top: 9px;
}

.cartProductItemColorBlock,
.cartProductItemPriceBlock {
    padding: 0;
}

.cartProductItemColorCircle {
    display: inline-block;
    background: #02CB81;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: 13px;
    float: left;
}

.cartProductItemColorName {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #4F6699;
    margin-bottom: 10px;
    top: 2px;
    position: relative;
}

.cartProductItemColorBlock button {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #ED0084;
    text-align: center;
    border: 2px solid #F00084;
    border-radius: 100px;
    width: 300px;
    height: 30px;
    padding: 0;
    background-color: #ffffff;
    top: 1px;
    position: relative;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.cartProductItemColorBlock button span {
    margin-right: 8px;
    font-size: 8px;
    top: -1px;
    position: relative;
}

.cartProductItemColorBlock button:hover {
    background-color: #F00084;
    color: #ffffff;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.cartProductItemPriceBlock {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 22px;
    color: #EC0486;
    padding-top: 3px;
    text-align: right;
}

.cartProductItemPriceBlock span {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #051F59;
    margin-right: 17px;
}

.cartProductItemFooter {
    margin: 0;
    background-color: #E6E8EE;
    padding: 18px 27px;
    height: 63px;
    overflow: hidden;
}

.cartProductItemFooterLeft {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
    color: #051F59;
    padding: 0;
    top: 4px;
}

.cartProductItemFooterRight {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 27px;
    color: #EC0486;
    text-align: right;
    padding: 0;
}

.cartProductItemFooterRight span {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #051F59;
    margin-right: 17px;
    top: -2px;
    position: relative;
}

.cartDesignItemBody {
    padding-bottom: 36px;
    margin-bottom: 50px;
    border-bottom: 1px solid #BDCAD8;
}

.cartProductItemOptionsButton {
    position: absolute;
    right: 0;
    top: -4px;
    opacity: 0;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.cartDesignItemBody:hover .cartProductItemOptionsButton {
    opacity: 1;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.cartProductItemOptionsButton button {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #828FAC;
    border: none;
    padding: 0;
    margin-right: 18px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.cartProductItemOptionsButton button span {
    margin-left: 5px;
    font-size: 14px;
    top: 3px;
    position: relative;
}

.cartProductItemOptionsButton button:hover {
    background-color: transparent;
    color: #EC0486;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.cartProductItemOptionsButton button:last-child {
    margin-right: 0;
}

.cartProductSizesAmountList .productSizeItem input,
.cartProductSizesAmountList .productSizeItem span {
    font-size: 18px;
}

.cartProductSizesAmountList {
    width: calc(100% - 138px);
    /*width: 100%;*/
    display: inline-block;
    float: left;
}

.cartProductItemPriceBreaksBlock {
    width: 138px;
    display: inline-block;
}

.cartProductSizesAmountListContainer {
    margin: 0;
}

.cartPriceBreaksButton {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #EC0486;
    border: 1px solid #F20086;
    box-sizing: border-box;
    display: block;
    width: 118px;
    height: 33px;
    border-radius: 0;
    padding: 0;
    float: right;
    top: 25px;
    position: relative;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.cartPriceBreaksButton:hover {
    background-color: #F20086;
    color: #ffffff;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.addNewProductButtonContainer {
    margin: 0;
    margin-bottom: 34px;
}

.addNewProductButtonContainer a {
    display: block;
    text-decoration: none;
    width: 100%;
}

.addNewProductButtonContainer a:hover {
    text-decoration: none;
}

.cartAddNewProductButton {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 50px #D6DDE7;
    height: 81px;
    width: 100%;
    text-align: center;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 81px;
    color: #ED0084;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.cartAddNewProductButton span {
    background-color: #ffffff;
    display: inline-block;
    border-radius: 12px;
    padding: 12px;
    font-size: 22px;
    margin-right: 15px;
    top: 3px;
    position: relative;
    color: #ED0084;
    box-shadow: 0px 0px 50px #D6DDE7;
}

.cartAddNewProductButton:hover {
    background-color: #ED0084;
    color: #ffffff;
    cursor: pointer;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.cartAddNewProductButton:hover span {
    box-shadow: none;
}

.cartFixedFooter {
    /*position: fixed;*/
    width: 100%;
    left: 0;
    bottom: 0;
    /*height: 150px;*/
    background-color: transparent;
    text-align: center;
    z-index: 9;

    position: sticky;
    margin-bottom: 50px;
}

.cartFixedFooter .container {
    padding: 25px 0;
    background-color: rgba(5, 31, 89, 0.8);
}

.stickyBreakpointContainer {
    position: relative;
    height: 153.8px;
    margin-bottom: -153.8px;
    width: 100%;
}

.stickyBreakpointContainer.isSticky  + .cartFixedFooter {
    background: rgba(5, 31, 89, 0.8);
    transition: all 0.3s;
}

.stickyBreakpointContainer.isSticky + .cartFixedFooter  .container {
    background-color: transparent;
    transition: all 0.3s;
}

.cartFixedFooterTotalPrice {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #FFFFFF;
    /*margin-top: 20px;*/
}

.cartFixedFooterTotalPrice i {
    font-style: normal;
}

.cartFixedFooterTotalPrice span {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
    margin-left: 8px;
}

.cartTotalPrice {
    text-decoration: underline;
    margin-left: 0 !important;
}

.confirmMyOrderButton {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    color: #FFFFFF;
    background: #ED0084;
    height: 35px;
    width: 265px;
    margin: 10px auto;
    border: none;
    border-radius: 0;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.confirmMyOrderButton:hover {
    background-color: #051F59;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;

}

.confirmMyOrderButton span {
    margin-left: 25px;
    top: 2px;
    position: relative;
}

.confirmDetails {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    color: #FFFFFF;
}

.messageModal .afterAddedToCartModalTitle {
    width: 100%;
    font-size: 20px;
    margin-bottom: 15px;
}

.messageModal .modal-header {
    padding-top: 20px;
}

.messageModal .modal-header .icon-close {
    top: 24px;
}

.deleteModalBody {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #4F6699;
    margin-bottom: 15px;
    padding: 0;
    text-align: center;
    width: 100%;
    overflow: hidden;
}

.deleteModalButtonsContainer button {
    width: 50%;
    border-radius: 0;
}

.deleteModalDelButton,
.saveModalSavButton {
    background-color: #ED0084;
    border-color: #ED0084;
    color: #ffffff;
}

.deleteModalCancelButton,
.saveModalCancelButton {
    border-color: #051F59;
    color: #051F59;
}

.messageModal .modal-dialog {
    max-width: 435px;
}

.deleteModalDelButton:hover,
.saveModalSavButton:hover {
    background-color: #ED0084;
    opacity: 0.8;
}

.deleteModalCancelButton:hover {
    /*background-color: #ffffff;*/
    /*opacity: 0.8;*/
}

.priceBreaksModal {
    position: absolute;
    z-index: -99999;
    left: -100%;
    top: -100%;
    opacity: 0;
}





/*DELIVERING STYLE START*/

.deliveringBreadcrumbsContainer {
    margin-bottom: 35px;
}

.deliveringBreadcrumbsName {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #ED0084;
    display: inline-block;
    float: left;
    margin-right: 30px;
}

.backToCartButton {
    font-family: 'Work Sans';
    font-size: 14px;
    line-height: 21px;
    padding: 0;
    color: #051F59;
    border: none;
    position: relative;
    top: -4px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;

}

.backToCartButton:hover {
    background-color: transparent;
    color: #ED0084;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;

}

.backToCartButton span {
    display: inline-block;
    margin-right: 7px;
    font-size: 13px;
    top: 1px;
    position: relative;
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.deliveringStepBlock {
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(214, 221, 231, 0.5);
    border-radius: 10px;
    padding: 15px 25px 35px 25px;
    margin-bottom: 15px;
}

.deliveringStepCounter {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #BDCAD8;
    margin-bottom: 10px;
    display: block;
    margin-left: -5px;
}

.deliveringStepName {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 16px;
    color: #051F59;
}

.pickupAddressContainer {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #051F59;
}

.pickupAddressContainer span {
    display: block;
    font-weight: bold;
}

.shippingAddressForm,
.pickupAddressContainer {
    display: none;
}

.shippingAddressFormVisible,
.pickupAddressContainerVisible {
    display: block;
}

.deliveringStepInputBox {
    width: calc(50% - 11px);
    margin-bottom: 10px;
    display: inline-block;
    margin-right: 22px;
}

.deliveringStepInputBox:nth-child(2),
.deliveringStepInputBox:nth-child(4) {
    margin-right: 0;
}

.deliveringStepInputBox label {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #051F59;
    margin-bottom: 10px;
}

.deliveringStepInputBox input {
    background: #FFFFFF;
    box-shadow: 0px 0px 50px #D6DDE7;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #08225C;
    width: 100%;
    height: 34px;
    border: 1px solid #ffffff;
    padding: 0 30px 0 14px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.shippingAddressTypeName {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #ED0084;
    margin-bottom: 23px;
}

.shippingAddressTypeContainer {
    margin: 16px 0 23px 0;
}

.shippingAddressTypeContainer label {
    width: calc(50% - 11px);
}

.shippingAddressTypeContainer label .checkmark {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #C5CBD0;
    border: 1px solid #C5CBD0;
    box-sizing: border-box;
    box-shadow: 0px 0px 50px rgba(214, 221, 231, 0.5);
    border-radius: 10px;
    height: 50px;
    display: inline-block;
    width: 100%;
    line-height: 49px;
    text-align: center;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.shippingAddressTypeContainer label input {
    position: absolute;
    z-index: -4;
    opacity: 0;
}

.shippingAddressTypeContainer label:hover span {
    border-color: #F00084;
    color: #F00084;
    background-color: #ffffff;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
    cursor: pointer;
}

.shippingAddressTypeContainer label input:checked~.checkmark {
    background-color: #F00084 !important;
    color: #ffffff !important;
    border-color: #F00084 !important;
    font-weight: bold !important;
}

.shippingAddressTypePickupFromGarage {
    margin-right: 22px;
}

.deliveringStepInputBoxTextarea {
    width: 100%;
    margin-right: 0;
}

.deliveringStepInputBoxTextarea textarea {
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 50px #D6DDE7;
    border: none;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #08225C;
    min-height: 114px;
    padding: 6px 14px;
}

.deliveringStepInputBox input::-webkit-input-placeholder,
.deliveringStepInputBox textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #C5CBD0;
}

.deliveringStepInputBox input::-moz-placeholder,
.deliveringStepInputBox textarea::-moz-placeholder { /* Firefox 19+ */
    color: #C5CBD0;
}

.deliveringStepInputBox input:-ms-input-placeholder,
.deliveringStepInputBox textarea:-ms-input-placeholder { /* IE 10+ */
    color: #C5CBD0;
}

.deliveringStepInputBox input:-moz-placeholder,
.deliveringStepInputBox textarea:-moz-placeholder { /* Firefox 18- */
    color: #C5CBD0;
}

.attachFileButton {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #ED0084;
    cursor: pointer;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
    display: inline-block;
    float: left;
    margin-right: 16px;
    position: relative;
}

.attachFileButton:hover {
    color: #051F59;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.attachFileButton span {
    margin-right: 5px;
    position: relative;
    top: 2px;
}

.cartFilestackUploader {
    /*display: block;*/
    position: absolute;
    z-index: 2;
    width: calc(100% + 40px);
    height: calc(100% + 20px);
    left: -20px;
    top: -10px;
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
    pointer-events: none;
}

.attachFileName {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #C5CBD0;
    display: inline-block;
    top: -2px;
    position: relative;
}

.deliveringStepBlock:last-child {
    margin-bottom: 50px;
}

.orderPriceBlock {
    background-color: #FFFFFF;
    border: 3px solid #BDCAD8;
    box-sizing: border-box;
    box-shadow: 0px 0px 50px rgba(214, 221, 231, 0.5);
    border-radius: 10px;
    padding: 46px 0 32px 0;
}

.orderPriceName {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #051F59;
    padding: 0 40px 0 40px;
    margin-bottom: 19px;
}

.orderPriceTableContainer {
    max-height: 130px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-right: 1px;
}

.orderPriceTable {
    width: calc(100% - 40px - 40px);
    margin: 0 auto;
}

.orderPriceTable tr td {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    position: relative;
    color: #051F59;
    height: 28px;
    line-height: 28px;
    text-align: right;
    white-space: nowrap;
}

.orderPriceItemCircle {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 7px;
    top: -7px;
    position: relative;
}

.orderServicesType {
    text-align: center;
}

.orderServicesType span {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 0px 3px;
}

.orderPrintingType {
    color: #F30084;
    border: 1px solid #F30084;
}

.orderEmbroideryType {
    color: #00D3E8;
    border: 1px solid #00D3E8;
}

.grayRow {
    background-color: #FBFBFB;
    /*background-color: darkgray;*/
    position: relative;
}

.orderPriceTable tr td:first-child {
    padding-left: 1px;
    text-align: left;
    width: 260px;
    max-width: 260px;
    /*padding-right: 15px;*/
}

.orderPriceTable tr td p {
    top: -5px;
    position: relative;
}

.orderPriceTable tr td:first-child p {
    overflow: hidden;
    display: inline-block;
    position: relative;
    top: 4px;
    width: 220px;
}

.orderPriceTable tr td:nth-child(2) {
    text-align: left;
}

.orderPriceTable tr td:first-child:before,
.orderPriceTable tr td:last-child:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: transparent;
}

.grayRow td:first-child:before,
.orderPriceTable tr td:first-child:before {
    left: -99%;
}

.grayRow td:first-child:before {
    background-color: #FBFBFB !important;
}

.grayRow td:last-child:after,
.orderPriceTable tr td:last-child:after {
    right: -99%;
}

.grayRow td:last-child:after {
    background-color: #FBFBFB !important;
}

.orderPriceTableContainer::-webkit-scrollbar {
    width: 5px;
}

.orderPriceTableContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
}

.orderPriceTableContainer::-webkit-scrollbar-thumb {
    background-color: #D8D8D8;
    border-radius: 100px;
    outline: none;
}

.orderPriceTable tr:hover,
.orderPriceTable tr:hover td:first-child:before,
.orderPriceTable tr:hover td:last-child:after {
    background-color: #FDE5F2 !important;
}

.orderPriceBottomPart {
    padding: 12px 40px 0px 40px;
}

.orderPriceTotalRow {
    margin: 0 0 24px 0;
}

.orderPriceTotalContainer {
    background: #E6E8EE;
    border-radius: 10px;
    padding: 11px 22px;
    width: 100%;
    display: flex;
}

.orderPriceTotalContainer .col-7,
.orderPriceTotalContainer .col-5 {
    padding: 0;
}

.orderPriceTotalTitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #051F59;
}

.orderPriceTotalSubTitle {
    font-family: 'Work Sans';
    font-size: 12px;
    line-height: 21px;
    color: #051F59;
    mix-blend-mode: normal;
    opacity: 0.5;
}

.orderPriceTotalNumber {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #051F59;
    text-align: right;
    padding-top: 5px !important;
}

.orderPriceDeliveringContainer {
    padding-top: 24px;
    border-top: 1px solid #F1F4F8;
}

.orderPriceDeliveringTitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
    color: #051F59;
    margin-bottom: 30px;
}

.orderPriceDeliveringTitle span {
    font-weight: bold;
    color: #ED0084;
    margin-left: 5px;
}

.orderPriceDeliveringList {
    /*overflow: hidden;*/
}

.orderPriceDeliveringItem {
    text-align: center;
    display: inline-block;
    width: calc((100% / 3) - 16px);
    margin-right: 23px;
    cursor: pointer;
}

.orderPriceDeliveringItem:last-child {
    margin-right: 0;
}

.orderPriceDeliveringItemName {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #051F59;
    margin-bottom: 4px;
}

.orderPriceDeliveringItemImageBlock {
    background-color: #ffffff;
    box-shadow: 0px 0px 50px rgba(214, 221, 231, 0.5);
    border-radius: 10px;
    height: 71px;
    line-height: 71px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #051F59;
    padding-left: 50px;
    overflow: hidden;
    position: relative;
}

.orderPriceDeliveringItemImageBlock span {
    position: absolute;
    font-size: 100px;
    color: #BDCAD8;
}

.orderPriceDeliveringItemImageBlock .icon-rabbit {
    top: -3px;
    left: -71px;
}

.orderPriceDeliveringItemImageBlock .icon-cheetah {
    top: 14px;
    left: -51px;
    font-size: 65px;
}

.orderPriceDeliveringItemImageBlock .icon-eagle {
    top: -5px;
    left: -36px;
    font-size: 77px;

}

.orderPriceDeliveringItemInfoBox {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #BDCAD8;
    margin-top: 6px;
}

.orderPriceDeliveringItemActive .orderPriceDeliveringItemInfoBox {
    color: #ED0084;
}

.orderPriceDeliveringItemInfoBox span {
    font-weight: bold;
}

.orderPriceDeliveringItemActive .orderPriceDeliveringItemName,
.orderPriceDeliveringItemActive .orderPriceDeliveringItemImageBlock span {
    color: #ED0084;
}

.orderPriceDeliveringItemActive .orderPriceDeliveringItemImageBlock {
    background-color: rgba(5, 31, 89, 0.1);
    color: #ED0084;
}

.placeOnOrderButton {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 71px;
    color: #FFFFFF;
    background-color: #051F59;
    border-radius: 10px;
    height: 71px;
    padding: 0 40px;
    width: 100%;
    border: none;
    margin-top: 32px;
    text-align: left;
    position: relative;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.placeOnOrderButton span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 40px;
    font-size: 18px;
}

.placeOnOrderButton:hover {
    background-color: #ED0084;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.orderPriceInfoAfterPanel {
    font-family: 'Work Sans';
    font-size: 14px;
    line-height: 21px;
    color: #BDCAD8;
    padding: 12px 40px;
}

.orderPriceInfoAfterPanelText {
    padding-bottom: 9px;
    margin-bottom: 7px;
    border-bottom: 1px solid #BDCAD8;
}

.orderPriceInfoAfterPanelLegend span {
    display: block;
    margin-bottom: 3px;
}

.inputContainer {
    position: relative;
}

.inputContainer .icon-ok {
    position: absolute;
    color: #00C4D8;
    font-size: 9px;
    right: 13px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    /*-webkit-transition: all .3s;*/
    /*-moz-transition: all .3s;*/
    /*-o-transition: all .3s;*/
    /*transition: all .3s;*/
    opacity: 0;
}

.successInputMessage .icon-ok {
    opacity: 1;
    /*z-index: initial;*/
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.errorInputMessage input,
.wrongFormatMessage input {
    border: 1px solid #ED0084;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.inputContainer .icon-close {
    position: absolute;
    color: #ED0084;
    font-size: 9px;
    right: 13px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    display: none;
}

.inputContainer .wrongFormatTitle {
    display: none;
    position: absolute;
    right: 0;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    color: #ED0084;
}

.wrongFormatMessage .wrongFormatTitle,
.wrongFormatMessage .icon-close {
    display: block;
}

.inputContainer .emptyFormatTitle {
    display: none;
    position: absolute;
    right: 0;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    color: #ED0084;
}

.errorInputMessage .emptyFormatTitle {
    display: block;
}

.stepError {
    border: 1px solid #ED0084;
}

.files-dropzone {
    border: 1px dashed #bdcad8;
    padding: 10px 20px;
    position: relative;
    overflow: hidden;
}

.files-dropzone > input {
    position: absolute;
    left: -100%;
    top: 0;
    width: 200%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
}

.attachFileButton {
    width: 100%;
    text-align: center;
}

.files-dropzone-active {
    border-color: #ED0084;
    background: rgba(237, 0, 132, 0.1);
}

.attachItemFile {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #C5CBD0;
}

.attachFilesPart {
    position: relative;
}

.attachFilesPart ul {
    margin-top: 5px;
}

.attachFilesPartTitle {
    font-family: 'Work Sans';
    font-size: 14px;
    line-height: 21px;
    color: #ED0084;
    margin-bottom: 10px;
}

.attachItemFile .icon-file {
    color: #ED0084;
    top: 1px;
    position: relative;
    margin-right: 2px;
}

.attachItemFile .icon-close {
    font-size: 7px;
    top: -0.9px;
    position: relative;
    margin-left: 2px;
    cursor: pointer;
}

.attachItemFile .icon-close:hover {
    color: #ED0084;
}

.attachFilesPart .imageLoader > div {
    left: 50%;
    top: 50%;
}

.attachFilesPart .imageLoader {
    background-color: transparent;
}

.promoCodeModal {
    margin-top: -8px;
}

.promoCodeModalTitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    text-decoration-line: underline;
    color: #F00084;
    cursor: pointer;
}

.promoCodeModal .__react_component_tooltip {
    opacity: 1;
    box-shadow: 0px 0px 50px #D6DDE7;
    padding: 7px 15px;
}

.promoCodeModalTitle:hover {
    opacity: 0.6;
}

.promoCodeInputContainer input {
    background-color: #FFFFFF;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #7583A3;
    width: 100%;
    height: 100%;
    border: 1px solid #ED0084;
    padding: 0 60px 0 15px;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 5px;
}

.promoCodeInputContainer button {
    position: absolute;
    height: 99%;
    background-color: #ED0084;
    border-radius: 5px;
    font-size: 12px;
    right: 0;
    top: 50%;
    width: 55px;
    color: #ffffff;
    line-height: 0;
    padding: 0;
    border: 1px solid #E93A9E;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.promoCodeInputContainer button:hover {
    opacity: 0.7;
}

.promoCodeInputContainer {
    position: relative;
    width: 189px;
    height: 24px;
    margin-top: 5px;
}

.promoCodeModalInfo {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #BDCAD8;
    text-align: center;
    margin-top: 5px;
    max-width: 189px;
}

.totalPriceWithPromoCode {
    margin-right: 5px;
}

.totalPriceWithPromoCode {
    display: none;
}

.activePromoCode .totalPriceWithPromoCode {
    display: inline;
}

.applyPromoCodeLoader {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: none;
    pointer-events: none;
}

.activePromoCode .totalPriceWithoutPromoCode {
    color: #ED0084;
    font-size: 14px;
    top: -3px;
    margin-right: 5px;
    position: relative;
    text-decoration: line-through;
}

.promoCodeTooltipComponent.is-sending .applyPromoCodeButton span {
    display: none;
}

.promoCodeTooltipComponent.is-sending .applyPromoCodeLoader {
    display: block !important;
}

.promoCodeModalInfo span {
    margin-right: 5px;
    top: 1px;
    position: relative;
    color: #f469b7;
}

.not-valid-promo {
    color: #ed0084 !important;
}

/*DELIVERING STYLE END*/

.cartProductItemFooterLeft span {
    color: #ed0084;
    margin-left: 5px;
}




























/*CART INPUT TEST CHANGES*/
.cartProductSizesAmountList input[type='number']::-webkit-inner-spin-button,
.cartProductSizesAmountList input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


.cartProductSizesAmountList .productSizeItem input,
.cartProductSizesAmountList .productSizeItem span {
    padding: 0;
    text-align: center;
}








/*@media screen and (min-width: 1800px) {*/

/*    .cartFixedFooter {*/
/*        top: 92px;*/
/*        width: calc((100% - 1170px) / 2 - 30px);*/
/*        left: calc((100% - 1170px) / 2 + 1170px + 25px);*/
/*        max-width: 360px;*/
/*        background-color: #ffffff;*/
/*        border: 1px solid #ED0084;*/
/*        height: auto;*/
/*        bottom: initial;*/
/*        padding-right: 25px;*/
/*        padding-left: 25px;*/
/*        position: fixed;*/
/*    }*/

/*    .cartFixedFooter .container {*/
/*        background-color: transparent;*/
/*    }*/

/*    .cartFixedFooterTotalPrice {*/
/*        margin-right: 0;*/
/*        color: #051F59;*/
/*    }*/

/*    .cartFixedFooterTotalPrice span {*/
/*        display: inline-block;*/
/*        clear: both;*/
/*        color: #ED0084;*/
/*        text-transform: lowercase;*/
/*        margin-left: 0;*/
/*    }*/

/*    .confirmMyOrderButton {*/
/*        margin-top: 28px;*/
/*        height: 49px;*/
/*        width: 100%;*/
/*    }*/

/*    .confirmDetails {*/
/*        color: #7A8BB2;*/
/*        margin: 10px auto 20px auto;*/
/*    }*/

/*    .cartFixedFooterTotalPrice i {*/
/*        display: block;*/
/*        margin-bottom: 23px;*/
/*    }*/

/*}*/


@media screen and (max-width: 1200px) {

    .cartPriceBreaksButton {
        font-size: 13px;
        width: 95px;
        height: 30px;
    }

    .cartProductSizesAmountList {
        width: calc(100% - 105px);
    }

    .cartProductItemPriceBreaksBlock {
        width: 105px;
    }

    .cartProductSizesAmountList .productSizeItem input, .cartProductSizesAmountList .productSizeItem span {
        font-size: 14px;
    }

    .productSizeItem label {
        font-size: 11px;
    }

    .totalTitle {
        font-size: 11px !important;
    }

    .productSizeItem input, .productSizeItem span {
        height: 30px;
    }

    .productSizeItem span {
        line-height: 26px;
    }

    .cartProductItemPriceBlock span {
        font-size: 11px;
        margin-right: 5px;
    }

    .cartProductItemPriceBlock {
        font-size: 14px;
        padding-top: 0;
    }

    .cartProductItemColorCircle {
        width: 20px;
        height: 20px;
    }

    .cartProductItemColorName {
        font-size: 13px;
        top: -4px;
    }

    .cartProductItemColorPriceBlock {
        padding: 12px 0;
    }

    .cartProductItemFooter {
        padding: 11px 15px;
        height: 50px;
    }

    .cartProductItemFooterRight span {
        font-size: 13px;
        top: -1px;
        margin-right: 10px;
    }

    .cartProductItemFooterRight {
        font-size: 18px;
    }

    .cartDesignItemBody {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
    }

    .cartProductDescription {
        font-size: 11px;
        height: 18px;
        line-height: 18px;
    }

    .shippingAddressTypeName {
        font-size: 15px;
        margin-bottom: 15px;
    }

    .shippingAddressTypeContainer label .checkmark {
        height: 40px;
        font-size: 13px;
        line-height: 39px;
    }

    .orderPriceTable tr td:first-child p {
        width: 140px;
    }

    .orderPriceTable tr td:first-child {
        width: 160px;
        max-width: 160px;
    }

    .orderPriceName {
        padding: 0 25px;
    }

    .orderPriceTable {
        width: calc(100% - 25px - 25px);
    }

    .orderPriceBottomPart {
        padding: 12px 25px 0px 25px;
    }

    .orderPriceTotalSubTitle {
        font-size: 9px;
        opacity: 0.6;
    }

    .orderPriceTotalTitle {
        font-size: 22px;
    }

    .orderPriceTotalNumber {
        font-size: 22px;
    }

    .orderPriceTotalContainer .col-7, .orderPriceTotalContainer .col-5 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .orderPriceTotalContainer {
        padding: 10px 20px;
    }

    .orderPriceDeliveringTitle {
        font-size: 11px;
        line-height: 18px;
    }

    .orderPriceDeliveringContainer {
        padding-top: 10px;
    }

    .orderPriceDeliveringItemName {
        font-size: 14px;
    }

    .orderPriceDeliveringItemInfoBox {
        font-size: 12px;
    }

    .orderPriceDeliveringItemImageBlock {
        font-size: 14px;
    }

    .orderPriceDeliveringItemImageBlock span {
        font-size: 90px;
    }

    .orderPriceDeliveringItemImageBlock .icon-cheetah {
        top: 16px;
        left: -60px;
    }

    .orderPriceDeliveringItemImageBlock .icon-eagle {
        font-size: 70px;
    }

    .orderPriceInfoAfterPanel {
        font-size: 12px;
    }

    .cartFixedFooter {
        padding: 0 30px;
    }

    .artDesignIteArtworkBlock {
        width: 65px;
    }

    .cartProductList {
        width: calc(100% - 65px - 30px);
    }

    .cartDesignItemArtworkList ul li img {
        width: 50px;
    }

    .cartProductItem .col-4 {
        flex: 0 0 250px;
        max-width: 250px;
    }

    .cartProductItem .col-8 {
        flex-basis: calc(100% - 250px);
        max-width: calc(100% - 250px);
    }

    .previewProductSliderContainer .image-gallery-slide-wrapper {
        margin-left: 55px;
        width: 180px;
        height: 225px;
    }

    .previewProductSliderContainer .image-gallery-slide img {
        width: 100% !important;
        height: 256px !important;
    }

    .cartProductItemFooterLeft {
        -ms-flex: 0 0 60%;
        flex: 0 0 60%;
        max-width: 60%;
        font-size: 12px;
    }

    .cartProductItemFooterRight {
        -ms-flex: 0 0 40%;
        flex: 0 0 40%;
        max-width: 40%;
    }

    .cartProductName span {
        font-size: 12px;
    }

    .cartProductName h5 {
        font-size: 17px;
    }

}



@media screen and (max-width: 992px) {

    .mobileVersionContainer .cartProductItemImgCol img {
        width: 35px;
    }

    .mobileVersionContainer .cartProductItemImgCol {
        -ms-flex: 0 0 50px;
        flex: 0 0 50px;
        max-width: 50px;
    }

    .mobileVersionContainer .cartProductItemTitleCol {
        flex: 0 0 calc(100% - 50px);
        max-width: calc(100% - 50px);
        margin-top: 2px;
    }

    .mobileVersionContainer .cartProductItemTitleCol .cartProductItemTitle {
        /*width: 335px;*/
        margin-left: 20px;
    }

    .mobileVersionContainer .cartProductItemAccordionCol .card {
        border-radius: 0;
        border: none;
    }

    .mobileVersionContainer .cartProductItemAccordionCol .card-header {
        padding: 0;
        border-radius: 0;
        border: none;
        background-color: transparent;
    }

    .mobileVersionContainer .cartProductItemAccordionCol .card-header .btn {
        width: 100%;
        text-align: left;
        padding: 0;
        border-radius: 0;
        padding: 10px 15px;

        font-family: Work Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #4F6699;
        border: none;
        border-top: 1px solid #E6E8EE;
    }

    .mobileVersionContainer .cartProductItemAccordionCol .card-header .btn:hover {
        background-color: transparent;
        text-decoration: none;
    }

    .mobileVersionContainer .cartProductItemAccordionCol .card-header .btn[aria-expanded="true"] {
        color: #ED0385;

        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
    }

    .mobileVersionContainer .cartProductItemAccordionCol .card-header h5 {
        line-height: 0;
    }

    .mobileVersionContainer .cartProductItemAccordionCol .card-header .btn:before,
    .mobileVersionContainer .cartProductItemAccordionCol .card-header .btn:after {
        content: '';
        position: absolute;
        width: 9px;
        height: 2px;
        border-radius: 3px;
        background: #4F6699;
        left: 0;
        top: 20px;

        -webkit-transform: translateY(-50%);
        -moz-transform:    translateY(-50%);
        -ms-transform:     translateY(-50%);
        -o-transform:      translateY(-50%);
        transform:         translateY(-50%);
    }

    .mobileVersionContainer .cartProductItemAccordionCol .card-header .btn:after {
        -webkit-transform: translateY(-50%) rotate(90deg);
        -moz-transform:    translateY(-50%) rotate(90deg);
        -ms-transform:     translateY(-50%) rotate(90deg);
        -o-transform:      translateY(-50%) rotate(90deg);
        transform:         translateY(-50%) rotate(90deg);

        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
    }

    .mobileVersionContainer .cartProductItemAccordionCol .card-header .btn[aria-expanded="true"]:after {
        -webkit-transform: translateY(-50%) rotate(0deg);
        -moz-transform:    translateY(-50%) rotate(0deg);
        -ms-transform:     translateY(-50%) rotate(0deg);
        -o-transform:      translateY(-50%) rotate(0deg);
        transform:         translateY(-50%) rotate(0deg);

        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
    }

    .mobileVersionContainer .cartProductItemAccordionCol .card-header.is-disabled .btn {
        pointer-events: none;
        opacity: 0.4;
    }

    .mobileVersionContainer .cartProductItemPPPCol .cartPriceBreaksButton {
        top: -3px;
        margin-left: 15px;
    }

    .mobileVersionContainer .cartProductItemPriceBlock {
        font-size: 24px;
    }

    .mobileVersionContainer .cartProductItemPriceBlock span {
        font-size: 14px;
        top: -2.5px;
        position: relative;
    }

    .mobileVersionContainer .cartProductItemColorBlock {
        padding-top: 2px;
    }

    .mobileVersionContainer .cartProductItemColorPriceBlock {
        border: none;
        margin-top: 0;
    }

    .mobileVersionContainer .cartProductItemFooterRight {
        color: #243A6D;
    }

    .mobileVersionContainer .cartProductItemFooterRight span {
        color: #506799;
        font-size: 14px;
    }

    .mobileVersionContainer .cartProductSizesAmountList {
        width: 100%;
    }

    .mobileVersionContainer .cartProductItemAccordionCol .card-body {
        padding-left: 0;
        padding-right: 0;
    }

    .mobileVersionContainer .cartProductName {
        width: 100%;
    }

    .mobileVersionContainer  .cartProductItemTotalPriceCol .productionCompletedDate {
        margin-top: 0;
    }

    .cartFixedFooter .container {
        padding: 10px 0;
    }

    .mobileVersionContainer .cartProductItemTitleCol .cartProductName h5 {
        display: block;
    }

    .mobileVersionContainer .cartProductItemTitleCol .cartProductItemOptionsButton {
        opacity: 1;
    }

    .mobileVersionContainer .cartProductItemAccordionCol {
        margin-top: 5px;
    }

    .cartDesignItem {
        padding: 15px 25px 20px 25px;
    }

    .mobileVersionContainer .cartDesignItemArtworkList ul {
        overflow: hidden;
    }

    .mobileVersionContainer .cartDesignItemArtworkList ul li {
        float: left;
        margin-right: 25px;
    }

    .stepCart .col-6 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .mobileVersionContainer .orderPriceDeliveringInfoTitle {
        font-family: Work Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 19px;
        color: #051F59;
        text-align: center;
        padding-top: 15px;
    }

    .mobileVersionContainer .orderPriceDeliveringTitle {
        text-align: center;
    }

    .mobileVersionContainer .orderPriceTotalContainer {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }

    .mobileVersionContainer .orderPriceTotalNumber {
        padding-top: 0 !important;
    }

    .mobileVersionContainer .placeOnOrderButton {
        margin-top: 0;
    }

    .mobileVersionContainer .placeOnOrderButton span {
        color: #F00084;
    }

    .mobileVersionContainer .orderRightPart {
        max-width: 545px !important;
        margin: 0 auto;
    }

    .mobileVersionContainer .orderPriceBlock {
        border-color: #e6e8ee;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .cartProductItemOptionsButtonIndicator {
        display: none;
    }

    .cartProductItemFooter .col-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .cartProductList {
        width: 100%;
    }


}




@media screen and (max-width: 768px) {

    .stepCart {
        padding-top: 15px;
    }

    .mobileVersionContainer .cartProductItemPriceBlock {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .mobileVersionContainer .cartProductItemFooterLeft {
        display: none;
    }

    .mobileVersionContainer .cartProductItemFooterRight {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
    }

    .mobileVersionContainer .cartProductItemTitleCol .cartProductName h5 {
         display: inline;
    }

    .mobileVersionContainer .backToCartButton {
        display: none;
    }

    .mobileVersionContainer .deliveringBreadcrumbsContainer {
        display: flex;
    }


    .cartProductItemColorBlock {
        display: none;
    }

    .mobileVersionContainer .cartProductItemTitleCol .cartProductItemOptionsButton {
        opacity: 0;
    }

    .mobileVersionContainer .cartProductItemOptionsButtonIndicator {
        position: absolute;
        right: 0;
        top: 0;
        color: #4F6699;
        font-size: 5px;
        display: block;
        padding: 5px 0;
    }

    .mobileVersionContainer .cartProductItemTitleCol {
        padding-right: 40px;
    }

    .mobileVersionContainer .cartProductItemOptionsButton.is-visible {
        opacity: 1 !important;
    }

    .mobileVersionContainer .cartProductItemTitleCol .cartProductItemOptionsButton {
        background: #FFFFFF;
        box-shadow: 0px 0px 50px #bfc7d2;
        right: 30px;
        padding: 5px 20px;
        top: -8px;
    }

    .mobileVersionContainer .cartProductItemOptionsButton button {
        top: -2.5px;
        position: relative;
    }

    .mobileVersionContainer .cartProductItemOptionsButton button span {
        font-size: 12px;
        top: 2px;
    }

    .mobileVersionContainer .cartProductItemOptionsButton button:first-child {
        margin-right: 25px;
    }

    .mobileVersionContainer .cartProductItemOptionsButtonIndicator.is-active {
        color: #ed0084;
    }

    #deleteProductModal .modal-footer {
        padding: 10px 20px 30px 20px;
    }

    .deleteModalBody {
        padding: 0 30px;
    }

}





@media screen and (max-width: 460px) {

    .cartDesignItem {
        padding: 15px;
    }

    .mobileVersionContainer .cartProductItemPPPCol .cartPriceBreaksButton {
        margin-left: 10px;
    }

    .cartAddNewProductButton {
        padding: 0 15px;
        font-size: 14px;
        height: 65px;
        line-height: 67px;
    }

    .cartAddNewProductButton span {
        font-size: 19px;
    }

    .cartFixedFooter {
        padding: 0 15px;
    }

    .mobileVersionContainer .orderPriceTable tr td:first-child {
        width: 104px;
        max-width: 104px;
        padding-right: 20px;
    }

    .mobileVersionContainer .orderPriceTable tr td:first-child p {
        width: 100%;
    }

    .mobileVersionContainer .shippingAddressTypeContainer .checkmark span {
        display: none;
    }

    .mobileVersionContainer .deliveringStepBlock {
        padding-bottom: 15px;
    }

    .mobileVersionContainer .shippingAddressTypePickupFromGarage {
        margin-right: 15px;
    }

    .mobileVersionContainer .shippingAddressTypeContainer label {
        width: calc(50% - 7.5px);
    }

    .mobileVersionContainer .deliveringStepBlock:last-child {
        margin-bottom: 25px;
    }

    .mobileVersionContainer .orderPriceDeliveringItemImageBlock {
        line-height: initial;
        padding: 0;
        height: 100%;
    }

    .mobileVersionContainer .orderPriceDeliveringItemInfoBox {
        overflow: hidden;
        height: 35px;
    }

    .mobileVersionContainer .orderPriceDeliveringItemBody {
        box-shadow: 0px 0px 50px rgba(214, 221, 231, 0.7);
        background-color: #ffffff;
        height: 130px;
        border-radius: 5px;
        padding-top: 12px;
        position: relative;
        overflow: hidden;
    }

    .mobileVersionContainer .orderPriceDeliveringItemImageBlock {
        box-shadow: none;
        background-color: transparent !important;
        height: auto;
        overflow: visible;
        position: initial;
    }

    .mobileVersionContainer .orderPriceDeliveringItem {
        margin-right: 15px;
        width: calc((100% / 3) - 10px);
    }

    .mobileVersionContainer .orderPriceDeliveringItem:last-child {
        margin-right: 0;
    }

    .mobileVersionContainer .orderPriceBottomPart {
        padding: 0px 15px 0px 15px;
    }

    .mobileVersionContainer .orderPriceDeliveringItemImageBlock p {
        position: absolute;
        left: 0;
        bottom: 9px;
        width: 100%;
        text-align: center;
    }

    .mobileVersionContainer .orderPriceDeliveringItemName {
        position: relative;
        z-index: 1;
    }

    .mobileVersionContainer .orderPriceDeliveringItemImageBlock .icon-rabbit {
        top: 25px;
        left: -60px;
    }

    .mobileVersionContainer .orderPriceDeliveringItemImageBlock .icon-cheetah {
        top: 43px;
        font-size: 80px;
        left: -72px;
    }

    .mobileVersionContainer .orderPriceDeliveringItemImageBlock .icon-eagle {
        top: 40px;
        left: -25px;
        font-size: 63px;
    }

    .mobileVersionContainer .throttleTitle {
        margin-top: -6px;
    }

    .mobileVersionContainer .orderPriceDeliveringTitle {
        margin-bottom: 15px;
    }

    .mobileVersionContainer .orderPriceBlock {
        padding: 20px 0 10px 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: none;
    }

    .mobileVersionContainer .orderPriceTable {
        width: calc(100% - 15px - 15px);
    }

    .mobileVersionContainer .orderPriceTotalPricePanel {
        background-color: #E6E8EE;
        padding-bottom: 18px;
        position: relative;
    }

    .mobileVersionContainer .orderPriceTotalPricePanel:before {
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        width: 200%;
        left: -199%;
        background-color: #E6E8EE;
        z-index: -1;
    }

    .mobileVersionContainer .orderPriceTotalPricePanel:after {
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        width: 200%;
        right: -199%;
        background-color: #E6E8EE;
        z-index: -1;
    }

    .mobileVersionContainer .orderPriceTotalContainer .col-7 {
        flex: 0 0 110px;
        max-width: 110px;
    }

    .mobileVersionContainer .orderPriceTotalContainer .col-5 {
        flex: 0 0 calc(100% - 110px);
        max-width: calc(100% - 110px);
        padding-top: 0 !important;
    }

    .mobileVersionContainer .totalPriceWithoutPromoCode {
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        /*color: #F00084;*/
    }

    .mobileVersionContainer .orderPriceTotalTitle {
        font-weight: bold;
        font-size: 16px;
        line-height: 21px;
        text-transform: uppercase;
        margin-top: 5px;
    }

    .mobileVersionContainer .orderPriceTotalSubTitle {
        width: 155px;
        line-height: 13px;
        margin-top: 5px;
    }

    .mobileVersionContainer .orderPriceTotalContainer {
        padding: 15px 20px;
    }

    .mobileVersionContainer .placeOnOrderButton {
        max-width: 93%;
        margin: 0 auto;
        display: block;
    }

    .mobileVersionContainer .orderPriceTotalRow {
        margin-bottom: 10px;
    }

    .mobileVersionContainer .orderPriceDeliveringContainer {
        border-top: none;
    }

    .mobileVersionContainer .orderPriceDeliveringTitle span {
        display: block;
    }

    .mobileVersionContainer .placeOnOrderButton {
        padding: 0 30px;
    }

    .mobileVersionContainer .placeOnOrderButton:hover span {
        color: #ffffff;

        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
    }

    .mobileVersionContainer .placeOnOrderButton span {
        right: 30px;
    }

    .mobileVersionContainer .deliveringStepInputBox {
        width: 100%;
        margin-right: 0;
    }

    .deliveryStepBody {
        overflow: hidden;
    }

    .mobileVersionContainer .cartProductItemImgCol {
        flex: 0 0 35px;
        max-width: 35px;
    }

    .mobileVersionContainer .cartProductItemTitleCol {
        flex-basis: calc(100% - 35px);
        max-width: calc(100% - 35px);
        padding-right: 40px;
    }

    .mobileVersionContainer .cartProductItemTitleCol .cartProductItemTitle {
        margin-left: 0px;
    }

    .mobileVersionContainer .cartProductItemImgCol img {
        width: 25px;
    }

    .mobileVersionContainer .cartProductItemTitleCol {
        margin-top: 0;
    }

    .mobileVersionContainer .cartProductName {
        margin-top: -6px;
    }

    .mobileVersionContainer .cartProductName h5 {
        font-size: 16px;
    }

    .mobileVersionContainer .cartProductItemPriceBlock {
        font-size: 20px;
    }

    .mobileVersionContainer .cartProductItemPriceBlock span {
        font-size: 13px;
        top: -1.5px;
    }


}



@media screen and (max-width: 360px) {

    .mobileVersionContainer .cartProductName h5 {
        font-size: 15px;
    }

    .mobileVersionContainer .cartProductName span {
        font-size: 11px;
        padding: 0 6px;
    }

    .mobileVersionContainer .cartProductItemPriceBlock span {
        font-size: 11px;
    }

    .mobileVersionContainer .cartProductItemPriceBlock {
        font-size: 16px;
    }

    .cartProductItemPriceBlock span {
        margin-right: 4px;
    }

    .cartPriceBreaksButton {
        font-size: 11px;
        width: 82px;
        height: 25px;
        margin-left: 7px !important;
        top: -1px !important;
    }

}






