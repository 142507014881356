.headerContainer {
    /*margin: 0 0 44px 0;*/
    border-bottom: 2px solid #F1F4F8;
    padding: 9px 0;
    /*overflow: hidden;*/
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 72px;
    top: 0;
    left: 0;
    background-color: #FFFFFF;
}

.btn-group {
    width: 100%;
}

.stepSelectProduct .btn-primary {
    width: 100%;
}

.saveProductModal {
    position: absolute;
    z-index: -99999;
    left: -100%;
    top: -100%;
    opacity: 0;
}

.stepNavigation .col-8 {
    text-align: center;
}

.stepNavButtonList {
    display: inline-block;
    top: 12px;
    position: relative;
}

.my-btn-secondary {
    background-color: transparent !important;
    display: inline-block;
    border: none;
    padding: 0;
    flex: initial !important;
    margin-right: 42px;
    cursor: pointer;
    position: relative;
}

.my-btn-secondary:last-child {
    margin-right: 0;
}

.non-active-link {
    pointer-events: none;
}




.stepNavItemCircle {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: #BDCAD8;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    display: inline-block;
    color: #FFFFFF;
    margin-right: 8px;
}

.stepNavItemName {
    display: inline-block;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #BDCAD8;
}

.my-btn-secondary.active .stepNavItemCircle {
    background-color: #ED0084;
}

.my-btn-secondary.active .stepNavItemName {
    color: #ED0084;
}

.headerLogoContainer img {
    max-width: 69px;
    position: relative;
    top: 4px;
}

.stepNavDeliveringTypeTitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #051F59;
    margin-bottom: 3px;
    margin-top: 8px;
}

.stepNavDeliveringTypeName {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 900;
    font-size: 15px;
    line-height: 19px;
    text-align: right;
    color: #ED0084;
    cursor: pointer;
    text-transform: uppercase;
    min-width: 225px;
    float: right;
}

.stepNavDeliveringTypeName span {
    font-weight: normal;
    text-transform: initial;
}

.stepSelectProductTitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #ED0084;
    margin-bottom: 8px;
}

.stepSelectProductSubTitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #BDCAD8;
    margin-bottom: 25px;
    max-width: 685px;
}

.my-btn-secondary[data-toggle="not-allowed"] {
    cursor: default;
}

.stepNavDeliveringTypeName[data-toggle="not-allowed"] {
    color: #DAE4EE;
    cursor: default;
}

.my-btn-secondary[data-toggle="not-allowed"] div {
    color: #DAE4EE;
    cursor: default;
}

.my-btn-secondary[data-toggle="not-allowed"] .stepNavItemCircle {
    background-color: #DAE4EE;
    color: #FFFFFF;
}

.stepNavDeliveringTypeTitle[data-toggle="not-allowed"] {
    color: #DAE4EE;
    cursor: default;
}

/* DELIVERING MODAL STYLE START*/
.deliveringModalTitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 21px;
    text-align: center;
    color: #051F59;
    width: 100%;
}

.deliveringModalDescription {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #051F59;
    margin: 0 auto;
    width: 490px;
}

.deliveringModalTypeList {
    text-align: center;
    margin-top: 57px;
}

.deliveringModalTypeItem {
    display: inline-block;
    width: 146px;
    margin-right: 27px;
    background: #FFFFFF;
    /*box-shadow: 0px 0px 30px rgba(0, 211, 228, 0.3);*/
    box-shadow: 0px 0px 30px rgba(189, 202, 216, 0.8);
    height: 262px;
    padding-top: 75px;
    position: relative;
    border: 1px solid #ffffff;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.deliveringModalTypeItem:hover {
    cursor: pointer;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
    border: 1px solid #FE259E;
    /*box-shadow: 0px 0px 30px rgba(0, 211, 228, 0.5);*/
}

.deliveringModalTypeItemActive {
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
    background-color: rgba(5, 31, 89, 0.1);
    border: 1px solid #FE259E !important;
    box-shadow: 0px 0px 30px rgba(254, 37, 158, 0.2) !important;;
    /*background-color: rgba(0, 211, 228, 0.2);*/
}

.deliveringModalTypeItemActive .deliveringModalTypeItemIcon span {
    color: #FE259E;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.deliveringModalTypeItemActive .deliveringModalTypeItemIcon span {
    color: #FE259E;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.deliveringModalTypeItemActive .deliveringModalTypeItemFooter {
    color: #FE259E !important;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}


.deliveringModalTypeItem:last-child {
    margin-right: 0;
}

.deliveringModalWave {
    text-align: center;
    color: #00D3E4;
    font-size: 13px;
    margin-bottom: 9px;
}

.deliveringModalTypeItemName {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: #FE259E;
    margin-bottom: 20px;
}

.deliveringModalTypeItemInfo {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #022058;
    padding-bottom: 5px;
    border-bottom: 1px solid #BDCAD8;
}

.deliveringModalTypeItemInfo strong {
    font-weight: bold;
    display: block;
}

.deliveringModalTypeItemFooter {
    font-family: 'Work Sans';
    font-size: 14px;
    line-height: 16px;
    color: #BDCAD8;
    padding: 17px 0;
}

.deliveringModalTypeItemIcon span {
    color: #BDCAD8;
    position: absolute;
}

.deliveringModalTypeItemIcon .icon-rabbit {
    left: -31px;
    top: -31px;
    font-size: 95px;
}

.deliveringModalTypeItemIcon .icon-cheetah {
    left: -35px;
    top: -27px;
    font-size: 83px;
}

.deliveringModalTypeItemIcon .icon-eagle {
    left: -17px;
    top: -50px;
    font-size: 95px;
}

.deliveringModalTypeItemBody {
    text-align: left;
    padding: 0 14px 0 14px;
}

.delivFootBg {
    font-size: 17px;
    text-align: center;
}

.modal-backdrop {
    opacity: 1 !important;
    background-color: rgba(32, 48, 57, 0.5);
}

.deliveringModalTypeResultName {
    font-family: 'Work Sans';
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    color: #051F59;
}

.deliveringModalTypeResultName span {
    color: #FE259E;
    font-size: 17px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.modal-footer {
    border: none;
}

.changeDeliveringTypeButton {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 900;
    font-size: 19px;
    color: #FFFFFF;
    background: #FD1C99;
    border: none;
    border-radius: 0;
    width: 144px;
    height: 42px;
    margin-left: 15px !important;
    top: -1px;
    position: relative;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.changeDeliveringTypeButton:hover {
    background-color: #051F59;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

/* #deliveringTypeModal */
.modal-footer {
    padding: 10px 39.5px 30px 39.5px;
}

.modal-dialog {
    max-width: 570px;
}

.modal-content {
    height: auto !important;
    border-radius: 10px;
    border: none;
}

.modal-header .icon-close {
    position: absolute;
    right: 21px;
    color: #BDCAD8;
    top: 34px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.modal-header .icon-close:hover {
    color: #FE259E;
    cursor: pointer;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.modal-header {
    padding-top: 29px;
    padding-bottom: 0;
}

.modal-header {
    border-bottom: none;
}

/* DELIVERING MODAL STYLE END*/

.cartHoverInfoBox {
    visibility: hidden;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(calc(-50% + -26px)) translateY(50px);
    -moz-transform: translateX(calc(-50% + -26px)) translateY(50px);
    -ms-transform: translateX(calc(-50% + -26px)) translateY(50px);
    -o-transform: translateX(calc(-50% + -26px)) translateY(50px);
    transform: translateX(calc(-50% + -26px)) translateY(50px);
    top: 65px;
    background: #FFFFFF;
    box-shadow: 0px 0px 50px #D6DDE7;
    width: 405px;
    padding: 15px 0;
    z-index: 999;
    /*cursor: initial;*/
    opacity: 0;
}

.cartHoverInfoBox:before {
    content: '';
    position: absolute;
    left: 0;
    top: -39px;
    width: 100%;
    height: 44px;
    background-color: transparent;
}

.cartHoverInfoBoxVisible {
    opacity: 1;
    visibility: visible !important;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
    -webkit-transform: translateX(calc(-50% + -26px)) translateY(0px);
    -moz-transform: translateX(calc(-50% + -26px)) translateY(0px);
    -ms-transform: translateX(calc(-50% + -26px)) translateY(0px);
    -o-transform: translateX(calc(-50% + -26px)) translateY(0px);
    transform: translateX(calc(-50% + -26px)) translateY(0px);
}

.cartHoverInfoBoxTitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    text-align: left;
    font-size: 14px;
    line-height: 21px;
    color: #051F59;
    margin-bottom: 2px;
    padding: 0 15px;
}

.cartHoverInfoBoxTitle span {
    color: #ED0385;
}

.cartHoverInfoBoxTitle {
    border-bottom: 1px solid #F1F4F8;
    padding-bottom: 8px;
}

.cartHoverInfoBoxTable {
    max-height: 235px;
    overflow: hidden;
    overflow-y: scroll;
}

.cartHoverInfoBoxTable::-webkit-scrollbar {
    width: 5px;
}

.cartHoverInfoBoxTable::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
}

.cartHoverInfoBoxTable::-webkit-scrollbar-thumb {
    background-color: #D8D8D8;
    border-radius: 100px;
    outline: none;
}

.cartHoverInfoBoxTable table {
    width: calc(100% - 15px - 15px);
    margin: 0 auto;
}

.cartHoverInfoBoxTable table tr td {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 0;
    padding: 6px 0;
    text-align: left;
    color: #051F59;
}

.cartHoverInfoBoxTable table tr td:first-child div {
    width: 49px;
    height: 57px;
    background-color: #D8D8D8;
    filter: brightness(1.15);
}

.cartHoverInfoBoxTable table tr td:first-child img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    mix-blend-mode: multiply;
}

.cartHoverInfoBoxTable table tr td:nth-child(2) {
    width: 170px;
    max-width: 170px;
}

.cartHoverInfoBoxTable table tr td:nth-child(2) span {
    width: 130px;
    height: 15px;
    line-height: 15px;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    top: -23px;
}

.cartHoverInfoBoxTable table tr td span {
    position: relative;
    top: -25px;
}

.cartHoverInfoBoxTable table tr:last-child td {
    padding-bottom: 0;
}

.cartHoverInfoBoxOuterContainer {
    position: absolute;
    top: -39px;
    left: 50%;
    width: 65px;
    height: 40px;
    overflow: hidden;
    -webkit-transform: translateX(-50%) rotate(180deg);
    -o-transform: translateX(-50%) rotate(180deg);
    -moz-transform: translateX(-50%) rotate(180deg);
    transform: translateX(-50%) rotate(180deg);
}

.cartHoverInfoBoxOuter {
    position: absolute;
    bottom: 8px;
    left: 0;
    font-size: 49px;
    text-shadow: 0px 0px 20px rgba(214, 221, 231, 0.67);
    color: #ffffff;
}

.cartHoverInfoBoxEmpty {
    width: 200px;
}

.cartHoverInfoBoxEmpty .cartHoverInfoBoxTitle {
    text-align: center;
}

.cartHoverInfoBoxEmpty .cartHoverInfoBoxTitle {
    border-bottom: none;
    padding-bottom: 0;
}











@media screen and (max-width: 1200px) {

    .stepNavButtonList {
        margin-left: -25px;
    }

    .stepNavDeliveringTypeName {
        font-size: 12px;
    }

}

@media screen and (min-width: 992px) {

    .mobileNavMenuContainer {
        display: none;
    }

}


@media screen and (max-width: 992px) {

    .desktopNavMenuContainer {
        display: none;
    }

    .mobileNavMenuContainer {
        display: block;
    }

    .stepNavButtonList {
        margin-left: 27px;
    }

    .stepNavItemName {
        font-size: 14px;
    }

    .my-btn-secondary {
        margin-right: 30px;
    }

    .stepNavItemCircle {
        width: 22px;
        height: 22px;
        font-size: 15px;
    }

    .stepNavigation .col-8 {
        padding: 0;
    }

    .headerContainer {
        padding: 5px 0;
        height: 62px;
    }

    .stepBody {
        padding-top: 80px;
    }

    /*nav menu hamburger*/

    #nav-hamburger {
        width: 30px;
        height: 20px;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        cursor: pointer;
        float: right;
        margin-top: 16px;
    }

    #nav-hamburger span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: #ED0084;
        border-radius: 4px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
    }

    #nav-hamburger span:nth-child(1) {
        top: 0px;
    }

    #nav-hamburger span:nth-child(2) {
        top: 8px;
    }

    #nav-hamburger span:nth-child(3) {
        top: 16px;
    }

    #nav-hamburger.open span:nth-child(1) {
        top: 8px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
    }

    #nav-hamburger.open span:nth-child(2) {
        opacity: 0;
        left: -60px;
    }

    #nav-hamburger.open span:nth-child(3) {
        top: 8px;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
    }

}


@media screen and (max-width: 768px) {

    .my-btn-secondary {
        display: none;
    }

    .my-btn-secondary.active {
        display: block !important;
        margin: 0;
    }

    .my-btn-secondary.active .stepNavItemName {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        color: #051F59;
        text-transform: capitalize;
    }

    .stepNavButtonList {
        margin: 0;
    }
    #nav-hamburger {
        width: 18px;
        height: 16px;
        margin-top: 13px;
    }

    #nav-hamburger span:nth-child(2) {
        top: 6px;
    }

    #nav-hamburger span:nth-child(3) {
        top: 12px;
    }

    #nav-hamburger.open span:nth-child(3) {
        top: 6px;
    }

    #nav-hamburger.open span:nth-child(1) {
        top: 6px;
    }


}
