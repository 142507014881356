.isHaveAccountMessage {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #051F59;
    text-align: center;

    border: 1px solid #ED0084;
    max-width: 570px;
    margin: 0 auto;
    padding: 15px;
    border-radius: 10px;
    background: rgba(237, 0, 132, 0.03);
    margin-top: 20px;
}

.isHaveAccountMessage a {
    color: #ED0084;
}

.signUpPageBody {
    margin-top: 30px;
}
