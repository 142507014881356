.initializePageBody {
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(214, 221, 231, 0.5);
    border-radius: 10px;
    padding: 50px;
    max-width: 700px;
    margin: 0 auto;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #051F59;
}

.initializePage {
    text-align: center;
    padding-top: 100px;
}

.initializePage h2 {
    margin-bottom: 40px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #051F59;
}

.initializePage button {
    margin-top: 40px;
    height: 35px;
    border: none;
    border-radius: 0;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    background-color: #ED0084;
    color: #FFFFFF;
    width: 300px;
    height: 50px;
}

.initializePageBody p span {
    color: #ED0084;
}

.initializePageBodyAfterSending {
    font-size: 18px;
}
