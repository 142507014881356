.stepEditProduct .row {
    margin: 0;
    width: 100%;
}

.singleProductSizesAmountBlock {
    padding: 0;
    /*padding-bottom: 20px;*/
    /*border-bottom: 2px solid #F1F4F8;*/
    margin-top: 8px;
}

.productSizeItem input,
.productSizeItem span {
    display: block;
    width: 100%;
    /*height: calc(1.5em + .75rem + 2px);*/
    /*padding: .375rem .75rem;*/
    /*background-clip: padding-box;*/
    padding-left: 13px;
    padding-right: 0;
    text-align: center;
    /*transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;*/


    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #051F59;
    background: #FFFFFF;
    box-shadow: 0px 0px 50px #D6DDE7;
    height: 34px;
    line-height: 34px;
}

.productSizeItem input:hover {
    border: 1px solid #EC0486;
}

.productSizeItem span {
    color: #ffffff;
    padding-left: 0;
    background-color: #051F59;
    box-shadow: 0px 0px 50px #D6DDE7;
    position: relative;
    border: 2px solid #051f59;
    line-height: 30px;
}

.totalTitle {
    text-transform: uppercase;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 21px !important;
}

.productSizeItem {
    display: inline-block;
    width: 11.15%;
    margin-right: 1.5%;
    float: left;
}

.productSizeItem:last-child {
    margin-right: 0;
}

.productSizeItem label {
    width: 100%;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #051F59;
}

.message-box-style {
    position: absolute;
    right: -140px;
    top: 0;
    font-family: 'Work Sans';
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
    border: 1px solid #ED0084;
    width: 130px;
    z-index: 4;
    background: #F20086;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 5px;
    /*-webkit-transition: all .3s;*/
    /*-moz-transition: all .3s;*/
    /*-o-transition: all .3s;*/
    /*transition: all .3s;*/
    visibility: hidden;
    opacity: 0;
}

.message-box-style:before {
    content:"\A";
    border-style: solid;
    border-width: 10px 15px 10px 0;
    border-color: transparent #ED0084 transparent transparent;
    position: absolute;
    left: -13px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.errorColorNumberInput .message-box-style {
    right: -150px;
    opacity: 1;
    visibility: visible;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.productAmountColorsBlock,
.productImagePositionList {
    display: inline-block;
}

.productSideImageBlock {
    margin-top: 30px;
}

.productAmountColorsBlock {
    width: 130px;
}

.productImagePositionList {
    width: calc(100% - 130px);
    padding-left: 15px;
}

.custom-control-label {
    font-size: 10px;
    font-weight: bold;
}

.custom-control-label::before,
.custom-control-label::after {
    top: 0;
}

.custom-control-inline {
    margin-right: 5%;
}

.custom-control:last-child {
    margin-right: 0;
}

.productSideName {
    border-bottom: 1px solid #cccccc;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.stepEditRightPart {
    position: relative;
    min-height: 370px;
}

.saveProductAfterEditing {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.konvajs-content {
    margin: 0 auto !important;
}

.konvajs-content canvas {
    width: 100% !important;
    height: 100% !important;
}

#konvaCanvasContainer {
    mix-blend-mode: multiply;
}

.chooseFileContainer {
    width: 161px;
    height: 227px;
    background-color: rgba(240, 0, 132, 0.1);
    border: 2px dashed #ED0084;
    box-sizing: border-box;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.chooseFileContainer input {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.chooseFileBody {
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
    width: 100%;
    text-align: center;

    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
}

.chooseFileBody .icon-select-artwork {
    display: block;
    margin-bottom: 9px;
}


.chooseFileContainer:hover {
    background-color: transparent;
    cursor: pointer;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.sidesButtonsList {
    overflow: hidden;
    margin-bottom: 4px;
    text-align: center;
}

.sidesButtonsList ul {
    padding: 0;
    clear: both;
    display: inline-block;
}

.sidesButtonsList ul li {
    float: left;
    width: 62px;
    /*margin-right: 10px;*/
    margin-right: 20px;
}

.sideButtonImageContainer {
    width: 62px;
    height: 62px;
    list-style: none;
    background-color: #D8D8D8;
    filter: brightness(1.15);
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
    opacity: 0.5;
    border: 2px solid transparent;
}

.sideButtonName {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #4F6699;
    margin-bottom: 8px;
}

.activeSideButton .sideButtonImageContainer {
    opacity: 1 !important;
    border: 1px solid #ED0084 !important;
}

.sidesButtonsList ul li img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    mix-blend-mode: multiply;
}

.sidesButtonsList ul li:hover {
    cursor: pointer;
    opacity: 1;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.artworkBySideIcon {
    position: absolute;
    left: -15px;
    bottom: -5px;
    background-color: #ffffff;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}

.artworkBySideIcon img {
    width: 20px !important;
    height: auto !important;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.showOnModelModal .sideButtonImageContainer {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #ED0084;
    position: relative;
    opacity: 1 !important;
    border: none !important;
    background-color: transparent;
    /*border: 1px solid #ED0084 !important;*/
}

.showOnModelModal .sideButtonImageContainer:hover {
    background-color: #D8D8D8;
}

.showOnModelModal .sideButtonName {
    visibility: hidden;
    z-index: -999;
    color: transparent;
}

.showOnModelModal span {
    position: absolute;
    left: 0;
    width: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.productMainCanvasImageContainer {
    position: relative;
    overflow: hidden;
    background: #F5F6F8;
}

.colorsButtonsList {
    clear: both;
    padding: 0;
    /*margin-top: 14px;*/
    /*border-top: 2px solid #F1F4F8;*/
    /*padding: 14px 0 0 0;*/
    /*overflow: hidden;*/
}

.colorsButtonsList ul {
    padding: 0;
    /*max-width: 432px;*/
}

.colorsButtonsList ul li {
    float: left;
    margin-right: 11px;
    margin-bottom: 8px;
    list-style: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: relative;
}

.colorsButtonsList ul li[data-tip="White"],
.afterAddedToCartModalTable span[data-tip="White"],
.cartProductItemColorCircle[data-tip="White"],
.orderPriceItemCircle[data-tip="White"] {
    border: 1px solid #bdcad8;
}

.colorsButtonsList ul li[data-tip="White"] span {
    border-color: #bdcad8 !important;
}

.inksColorsPaletteDropdownList button[title="White"],
.inksColorsPalettesList button[title="White"] {
    border: 1px solid #bdcad8 !important;
}

.activeColorButton {
    position: absolute;
    left: 50%;
    top: 50%;
    width: calc(100% + 7px);
    height: calc(100% + 7px);
    border: 1px solid;
    border-radius: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: -1;
}

.colorsButtonsList ul li:hover {
    cursor: pointer;
    opacity: 0.8;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.productSideOptionsColorsAmount select {
    max-width: 65px;
}

.productSideOptionsUploadedImageInfo img {
    width: 18px;
    float: right;
    top: 5px;
    position: relative;
}

.productSideOptionsUploadedImageInfo img:hover {
    cursor: pointer;
    opacity: 0.5;
}

.imageLoader {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255, 0.7);
}

.imageLoader > div {
    position: absolute;
    /*left: 52%;*/
    /*top: 45%;*/
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.stepEditProductLoader > div {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.btn-secondary {
    cursor: pointer;
}

.filterChangeGender .btn-secondary:not(:disabled):not(.disabled).active {
    background-color: #2196F3;
    border-color: #2196F3;
}

.filterChangeGender {
    width: 250px;
    margin-top: 20px;
}

.filterChangeGender .btn {
    width: 50%;
}









.singleProductInfoPart {
    -ms-flex: 0 0 calc(100% - 472px);
    flex: 0 0 calc(100% - 472px);
    max-width: calc(100% - 472px);
    padding: 0;
    padding-right: 27px;
    z-index: 4;
}

.singleProductCanvasPart {
    -ms-flex: 0 0 472px;
    flex: 0 0 472px;
    max-width: 472px;
    padding: 0;
}

.singleProductSubTitle {
    font-family: 'Work Sans';
    margin-bottom: 13px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #ED0084;
}

.singleProductSubTitle.productEditingMode {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    background-color: #00D3E4;
    border-radius: 5px;
    float: left;
    padding: 3px 8px;
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 0;
    top: 3px;
    position: relative;
}

.singleProductTitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #051F59;
    margin-bottom: 15px;
}

.singleProductTitle.productEditingMode {
    color: #00D3E4;
    line-height: 30px;
}

.singleProductDescription {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #4F6699;
    margin-bottom: 15px;
}

.singleProductDescription span {
    float: left;
}

.singleProductDescription span {
    margin-right: 5px;
}

.singleProductDescription button {
    border: none;
    padding: 0;
    color: #051F59;
    text-decoration: underline;
    position: relative;
    top: -1px;
}

.singleProductDescription button:hover {
    background-color: transparent;
    opacity: 0.5;
}

.servicesTypeRadioButtons {
    /*margin: 20px 0;*/
    position: relative;
    z-index: 2;
    /*display: none;*/
}

.servicesTypeRadioButtons:after {
    content: '';
    background: #ffffff;
    width: 100%;
    position: absolute;
    height: 35px;
    left: 0;
    bottom: -34px;
    border-top-right-radius: 10px;
}

.serviceTypeButton {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    color: #97A3B0;
    box-sizing: border-box;
    width: 220px;
    height: 43px;
    border: none;
    position: relative;
    padding-left: 50px;

    background-color: #E4E6ED;
    box-shadow: 0px 0px 30px rgba(214, 221, 231, 0.5);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.serviceTypeButton span {
    margin-right: 18px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.serviceTypeButton.printingType span {
    font-size: 16px;
    left: 35px;
}

.serviceTypeButton.embroideryType span {
    font-size: 22px;
    left: 50px;
}

.embroideryType {
    /*pointer-events: none;*/
    /*cursor: not-allowed !important;*/
}

.servicesTypeRadioButtons button:hover {
    background-color: #E6E8EE;
}

.servicesTypeRadioButtons button:nth-child(1) {
    margin-right: 10px;
}

.advancedSettingsButton {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
    color: #051F59;
    display: inline-block;
    float: right;
    height: 43px;
    line-height: 43px;
}

.advanced-settings-is-active .advancedSettingsButton {
    color: #EB158D;
}

.advanced-settings-switch .react-switch-handle {
    box-shadow: none !important;
}

.advanced-settings-switch {
    margin-left: 15px;
    top: 4px;
    position: relative;
}

.react-switch-handle {
    border: 2px solid #BDCAD8 !important;
}

.advanced-settings-is-active .react-switch-handle {
    border: 2px solid #EB158D !important;
}

.advanced-settings-switch .react-switch-bg {
    border: 2px solid #BDCAD8;
}

.advanced-settings-is-active .advanced-settings-switch .react-switch-bg {
    border: 2px solid #EB158D;
}








.activeType {
    background-color: #ffffff !important;
    color: #ED0084 !important;
}

.saveForLaterButton {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    float: right;
    background: transparent;
    border: none;
    color: #4F6699;
    width: auto;
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 2;
    padding: 6px 10px 6px 5px;
}

.saveForLaterButton:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    display: inline-block;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(214, 221, 231, 0.5);
    border-radius: 30px;
    z-index: -1;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.saveForLaterButton:hover {
    color: #051F59;
    background-color: transparent !important;
    /*opacity: 0.7;*/
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.saveForLaterButton:hover:before {
    width: 100%;
    border-radius: 10px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.saveForLaterButton span {
    color: #00C4DA !important;
    margin-right: 10px;
    font-size: 16px;
    top: 2px;
    left: 2px;
    position: relative;
}

.artworkDisclaimer {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #bdcad8;
    text-align: left;
    margin-top: -5px;
    margin-bottom: 5px;
}

.artworkDisclaimer span {
    margin-right: 5px;
    top: 1px;
    position: relative;
}

.spr-panel {
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(214, 221, 231, 0.5);
    border-radius: 10px;
    padding: 0px 25px 15px 25px;
}

.spr-panel .table {
    z-index: 2;
    position: relative;
    background-color: #ffffff;
}

.productOptionsContainer {
    padding-top: 15px;
    padding-bottom: 20px;
    margin-top: 15px !important;
}


.singleProductSidesOptionsTable .table thead th {
    border-top: 0;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #4F6699;
    padding: 9px 0;
    border-bottom: 2px solid #F1F4F8;;
}

.singleProductSidesOptionsTable .table thead th:nth-child(3) {
    text-align: center;
}

.singleProductSidesOptionsTable .table td,
.singleProductSidesOptionsTable .table th {
    padding: 0;
    height: 36px;
    border-top: none;
}

.singleProductSidesOptionsTable .table tbody tr {
    cursor: pointer;
}

.singleProductSidesOptionsTable .table th span {
    position: relative;
    display: inline-block;
}

.singleProductItemSideName {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #4F6699;
    line-height: 36px;
    width: 130px;
}

.uploadArtworkFromTable {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #4F6699;
    width: 161px;
    height: 30px;
    padding: 0;
    border-radius: 0;
    border: 1px solid #F1F4F8;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
    position: relative;
    overflow: hidden;
}

.uploadArtworkFromTable:hover {
    background-color: #ffffff;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.uploadArtworkFromTable .icon-arrow-down {
    font-size: 9px;
    margin-left: -9px;
    color: #ED0084;
    opacity: 0;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.uploadArtworkFromTable input {
    position: absolute;
    left: -100%;
    top: 0;
    width: 200%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
}

.filestackUploadProgressBar {
    background-color: #FFFFFF;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #051F59;
    border: 1px solid #051F59;
    display: none;
}

.filestackUploadProgressBarLine {
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background-color: #ffd5ec;
    z-index: 1;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.addToCartProgressBar {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    color: #FFFFFF;
}

.filestackUploadProgressBarSizeValue {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 2;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.currentUploadSizeValue {
    margin-right: 5px;
}

.finalUploadSizeValue {
    margin-left: 5px;
}

.singleProductSidesOptionsTable .table-hover tbody tr:hover {
    background-color: #E6E8EE;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.singleProductSidesOptionsTable .table-hover tbody tr:hover .uploadArtworkFromTable {
    border-color: #ED0084;
    color: #ED0084;
    font-weight: bold;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

 .singleProductSidesOptionsTable .table-hover tbody tr:hover .singleProductItemSideName {
     color: #051F59;
     padding-left: 7px;
     font-weight: bold;
     -webkit-transition: all .3s;
     -moz-transition: all .3s;
     -o-transition: all .3s;
     -ms-transition: all .3s;
     transition: all .3s;
 }

.singleProductSidesOptionsTable .table-hover tbody tr:hover .uploadArtworkFromTable .icon-arrow-down {
    opacity: 1;
    margin-left: 0;
    margin-right: 5px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.singleProductItemSideUploadedImageInfo {
    padding: 4px 0 !important;
}

.singleProductItemSideUploadedImageInfo img {
    height: 26px;
    position: relative;
    top: 1px;
    float: left;
    width: 26px;
    object-fit: contain;
    overflow: hidden;
}

.itemSideUploadedImageName {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #4F6699;
    margin-left: 15px;
    margin-right: 12px;
    position: relative;
    top: 4px;
    overflow: hidden;
    white-space: nowrap;
    float: left;
    max-width: 200px;
    width: calc(100% - 26px - 45px);
}

.advanced-settings-is-active .itemSideUploadedImageName {
    max-width: 92px;
}

.deleteUploadImageButton {
    font-size: 10px;
    top: 0.5px;
    position: relative;
    margin-left: 5px;
    color: #4F6699;
    cursor: pointer;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
    display: inline-block;
}

.deleteUploadImageButton:hover {
    color: #ED0084;
    transform: rotate(90deg);
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.singleProductItemSideColorsAmount {
    min-width: 212px;
}

/*.singleProductItemSideColorsAmount {*/
/*    !*width: 190px;*!*/
/*    !*text-align: center;*!*/
/*    padding: 5px 0 !important;*/
/*}*/

/*.singleProductItemSideColorsAmount span {*/
/*    position: relative;*/
/*}*/

/*.singleProductItemSideColorsAmount select {*/
/*    width: 65px;*/
/*    height: 28px;*/
/*    line-height: initial;*/
/*    padding: 0 0 0 13px;*/
/*    border-radius: 0;*/
/*    border: none;*/
/*    -webkit-box-shadow: 0px 0px 50px #D6DDE7;*/
/*    box-shadow: 0px 0px 50px #D6DDE7;*/
/*    font-family: 'Work Sans';*/
/*    font-style: normal;*/
/*    font-weight: bold;*/
/*    font-size: 16px;*/
/*    color: #051F59;*/
/*    outline: none;*/
/*}*/

.singleProductItemSideColorsAmountWrapper {
    line-height: 0;
    text-align: center;
}

.inksColorsPalettesList,
.inksColorsPalettesListContainer,
.sideColorsAmountSelectContainer {
    display: inline-block;
}

.sideColorsAmountSelectContainer {
    float: initial;
}

.advanced-settings-is-active .singleProductItemSideColorsAmountWrapper {
    text-align: initial;
}

.advanced-settings-is-active .sideColorsAmountSelectContainer {
    float: left;
}

.artWidthSelect .garage-select__control {
    width: 78px;
    margin: 0 auto;
}

.incColorSelect .garage-select__control {
    width: 55px;
}

/* CUSTOM SELECT STYLE START */

.garage-select__control {
    border: 1px solid transparent;
    /*background-color: #FFFFFF;*/
    box-shadow: 0px 0px 50px #D6DDE7;
    border-radius: 0;
    height: 28px;
    min-height: 28px;
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.garage-select__control.garage-select__control--menu-is-open,
.garage-select__control:hover,
.garage-select__control:focus {
    border: 1px solid #ED0084;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.garage-select__value-container {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.garage-select__placeholder {
    width: calc(100% - 22px );
    margin: 0 !important;
    overflow: hidden;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #C0CCDA !important;
    padding-left: 17px;
}

.garage-select__indicators {
    position: absolute;
    right: 0;
    top: 0;
    width: 22px;
    height: 100%;
}

.garage-select__indicator .icon {
    display: block;
    color: #022058;
    font-size: 6px;
    position: relative;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.garage-select__control--menu-is-open .garage-select__indicator .icon {
    -webkit-transform: translate(-50%, -50%) rotate(-90deg);
    -moz-transform: translate(-50%, -50%) rotate(-90deg);
    -ms-transform: translate(-50%, -50%) rotate(-90deg);
    -o-transform: translate(-50%, -50%) rotate(-90deg);
    transform: translate(-50%, -50%) rotate(-90deg);
    color: #ED0084;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.garage-select__indicator {
    display: block !important;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0 !important;
    text-align: center;
}

.garage-select__single-value {
    max-width: calc(100% - 22px) !important;
    width: calc(100% - 22px);
    padding-left: 17px;
    margin: 0 !important;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #2D4776 !important;
}

.garage-select__menu {
    margin: -1px 0 0 0 !important;
    border-radius: 0 !important;
    background: #FFFFFF;
    border: 1px solid #ED0084;
    box-shadow: 0px 0px 50px #D6DDE7;
    width: 200px;
    padding: 0 0 0 8px;
}

.garage-select__option {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #2D4776;
    padding: 5px 0;
    border-bottom: 1px solid #E9EDF1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.garage-select__option--is-focused,
.garage-select__option--is-selected{
    color: #ED0084 !important;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.garage-select__menu-list {
    padding: 0 !important;
}

.garage-select__menu-list::-webkit-scrollbar {
    width: 3px;
}

.garage-select__option:last-child {
    border-bottom: none;
}

.incColorSelect .garage-select__menu {
    padding-left: 7px;
    padding-right: 7px;
}

.incColorSelect .garage-select__menu-list {
    overflow-y: initial;
}

.incColorSelect .garage-select__option {
    text-align: center;
}

.incColorSelect .garage-select__option:last-child {
    font-size: 14px;
}


/* CUSTOM SELECT STYLE END */

.inksColorsPalettesListContainer {
    height: 28px;
    margin-left: 9px;
    position: relative;
}

.inksColorsPalettesList {
    height: 100%;
    padding: 5px;
    background-color: #FFFFFF;
    min-width: 148px;
}

.singleProductSidesOptionsTable table td,
.singleProductSidesOptionsTable table th {
    vertical-align: middle;
}

.inksColorsPalettesList li {
    float: left;
    margin-right: 6px;
    position: relative;
}

.inksColorsPalettesList li:last-child {
    margin-right: 0;
}

.inksColorsPaletteDropdownButton {
    width: 18px;
    height: 18px;
    display: block;
    background: #FFFFFF;
    border: 1px solid #C2CDDB;
    box-sizing: border-box;
    box-shadow: 0px 0px 50px #D6DDE7;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #C2CDDB;

    padding: 0;
    border-radius: 0;
}

.inksColorsPaletteDropdownButton.is-enabled {
    color: transparent;
}

.inksColorsPaletteDropdown {
    /*position: absolute;*/
    position: fixed;
    left: 0;
    top: 25px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 50px #D6DDE7;
    z-index: 3;
    padding: 5px 13px;
    width: 220px;
    cursor: initial;
    visibility: hidden;
    opacity: 0;

    /*-webkit-transition: all 0.3s;*/
    /*-moz-transition: all 0.3s;*/
    /*-o-transition: all 0.3s;*/
    /*transition: all 0.3s;*/
}

.inksColorsPaletteDropdown.is-visible {
    visibility: visible;
    opacity: 1;
    /*-webkit-transition: all 0.3s;*/
    /*-moz-transition: all 0.3s;*/
    /*-o-transition: all 0.3s;*/
    /*transition: all 0.3s;*/
}

.inksColorsPaletteTitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #143064;
    border-bottom: 2px solid #F1F4F8;
    margin-bottom: 7px;
    padding-bottom: 2px;
}

.inksColorsPaletteDropdownItem {
    width: calc((100% / 5) - 3.2px);
    height: 24px;
    float: left;
    margin-right: 4px;
    margin-bottom: 5px;
    border-radius: 0;
    padding: 0;
    border-style: solid;
    border-width: 0px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.inksColorsPaletteDropdownItem:nth-child(5),
.inksColorsPaletteDropdownItem:nth-child(10),
.inksColorsPaletteDropdownItem:nth-child(15),
.inksColorsPaletteDropdownItem:nth-child(20),
.inksColorsPaletteDropdownItem:nth-child(25),
.inksColorsPaletteDropdownItem:nth-child(30),
.inksColorsPaletteDropdownItem:nth-child(35),
.inksColorsPaletteDropdownItem:nth-child(40),
.inksColorsPaletteDropdownItem:nth-child(45),
.inksColorsPaletteDropdownItem:nth-child(50),
.inksColorsPaletteDropdownItem:nth-child(55){
    margin-right: 0;
}

.inksColorsPaletteDropdownItem span {
    width: 100%;
    height: 100%;
    display: block;
}

.inksColorsPaletteDropdownItem:hover {
    /*padding: 5px;*/
    border-width: 1px;
    padding: 5px;
    background-color: transparent;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.inksColorsPaletteDropdownItem.is-active {
    padding: 5px;
    border-width: 3px;
}

.chooseForMeInkColor {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #ED0084;
    border-color: transparent;
    width: 100%;
    border-radius: 0;
    text-align: center;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.singleProductItemSideColorsAmount span.has-color {
    box-shadow: 0px 0px 50px #D6DDE7;
    border: 1px solid transparent;
    color: transparent;
}

.colorsButtonsListTitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #051F59;
    margin-bottom: 15px;
}

.activeColorButtonName {
    display: inline-block;
    margin-left: 19px;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #4F6699;
}

.activeColorButtonName span {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    top: -2px;
    margin-right: 5px;
    position: relative;
    display: inline-block;
}

.activeColorButtonName[title="White"] span {
    border: 1px solid #bdcad8;
}

.singleProductSizesAmountTitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 19px;
    color: #051F59;
}

.productPricePerOneTitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #051F59;
    margin-right: 13px;
}

.productPricePerOne {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 22px;
    color: #EC0486;
    margin-right: 15px;
}

.priceBreaksButton {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #EC0486;
    border: 1px solid #F20086;
    width: 118px;
    height: 33px;
    padding: 0;
    border-radius: 0;
    top: -3px;
    position: relative;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.priceBreaksButtonDisabled {
    opacity: 0.3;
    pointer-events: none;
    /*cursor: not-allowed;*/
}

.priceBreaksButton:hover {
    background-color: #EC0486;
    color: #ffffff;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.productPricePerOneContainer {
    text-align: right;
    padding: 20px 0 0 0;
}

.singleProductAddToCartContainer {
    padding: 8px 24px;
    background: #E6E8EE;
    height: 63px;
}

.totalPriceAfterCalculate {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #4F6699;
    text-transform: uppercase;
    margin-top: -3px;
}

.totalPriceBlock {
    font-size: 16px;
    line-height: 28px;
    color: #051F59;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 10px;
    /*top: 2px;*/
    /*position: relative;*/
}

.embroideryPriceNotice {
    text-align: right;
    width: 100%;
    line-height: normal;
    color: #051F59;
    font-size: 14px;
    margin-top: 8px;
}

.embroideryPriceNotice a {
    color: #f20086;
    font-weight: bold;
}

.volumeSavingsBlock {
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    display: inline-block;
    color: #ED0084;
    text-transform: none;
    border: 1px solid #ED0084;
    padding: 2px 5px;
    position: relative;
    /*margin-left: 10px;*/
    top: -1px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.volumeSavingsBlock:hover {
    cursor: pointer;
    background-color: #ED0084;
    color: #FFFFFF;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.totalPriceHidden {
    visibility: hidden;
    color: transparent;
}

.productionCompletedDate {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    color: #051F59;
}

.productionCompletedDate span {
    text-decoration: underline;
    cursor: pointer;
}

.additionalPercentageForTotalPriceBySpeed {
    margin-top: 15px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    color: #FFFFFF;
    background-color: #051F59;
    padding: 0 15px;
    display: inline-block;
    width: auto;

    -webkit-transform: translate3d(0, 101%, 0);
    -moz-transform: translate3d(0, 101%, 0);
    -o-transform: translate3d(0, 101%, 0);
    transform: translate3d(0, 101%, 0);

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.additionalPercentageForTotalPriceBySpeed.is-visible {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.singleProductAddToCartContainer .col-6 {
    padding: 0;
}

.stepCalculationMainButton {
    width: 169px;
    height: 35px;
    border: none;
    border-radius: 0;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
}

.stepCalculationMainButton span {
    margin-left: 17px;
    top: 1px;
    position: relative;
    font-size: 16px;
}

.notValidatedSettingsInfoBox {
    font-family: 'Work Sans';
    font-size: 14px;
    color: #F20086;
    border: 1px solid #ED0084;
    width: 100%;
    text-align: center;
    padding: 6px 0;
    position: relative;
    top: 5px;
    max-width: 300px;
    float: right;
}

.addToCart {
    background-color: #ED0084;
    color: #FFFFFF;
    float: right;
    margin-top: 5.5px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.addToCart:hover {
    background-color: #051F59;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.saveProductAfterEditing {
    color: #ffffff;
    float: right;
    margin-top: 5.5px;
    line-height: 20px;
    float: right;
    width: calc(100% - 40% - 5px);
    background-color: #00D3E4;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.saveProductAfterEditing span {
    font-size: 10px;
    margin-left: 13px;
    top: 0px;
}

.cancelEditingProductButton {
    width: calc(100% - 60% - 5px);
    margin-right: 5px;
    height: 35px;
    border-radius: 0;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: #EC0486;
    float: left;
    margin-top: 5.5px;
    line-height: 20px;
    background-color: #ffffff;
    transition: all .3s;
    display: inline-block;
    border: 1px solid #EC0486;
}

.cancelEditingProductButton:hover {
    background-color: #EC0486;
    color: #FFFFFF;
}

.saveProductAfterEditing:hover {
    background-color: #00D3E4;
    opacity: 0.6;
}

.cancelEditingProductButton span {
    font-size: 10px;
    margin-left: 10px;
}

.offsetTableRow {
    height: 8px;
}

.priceBreaksModalTitle,
.guidelinesModalTitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #051F59;
    margin-bottom: 8px;
}

.guidelinesModalTitle {
    margin-bottom: 29px;
    font-weight: 900;
}

.modalErrorTitle {
    color: #ED0084;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.priceBreaksModalDescription,
.guidelinesModalDescription {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #4F6699;
    margin-bottom: 25px;
}

.guidelinesModalDescription .col-9,
.guidelinesModalDescription .col-3 {
    padding: 0;
}

.guidelinesModalDescription .col-9 {
    padding-right: 20px !important;
}

.guidelinesModalDescription .col-3 {
    margin-top: -15px;
}

.guidelinesModalDescription .col-3 img {
    width: 130px;
    float: right;
}

.guidelinesModalSubTitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #051F59;
    margin-bottom: 25px;
}

.modalErrorDescription {
    text-align: center;
    margin-bottom: 35px;
}

.modalErrorDescription span {
    color: #ED0084;
}

#priceBreaksModal .modal-body {
    padding: 0 40px;
    padding-bottom: 30px;
}

#priceBreaksModal .modal-header {
    padding: 35px 40px 0 40px;
}

.priceBreaksModalTable th {
    padding-top: 0 !important;
    text-align: center;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    border-bottom: 2px solid #F1F4F8;;
    padding-bottom: 10px;
    color: #051F59;
    width: 33%;
}

.priceBreaksModalTable th:nth-child(1) {
    text-align: left;
}

.priceBreaksModalTable th:nth-child(2) {
    text-align: center;
}

.priceBreaksModalTable th:nth-child(3) {
    text-align: right;
}

.priceBreaksModalTable td {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #051F59;
    padding: 6px 0;
}

.priceBreaksModalTable td:nth-child(2) {
    font-weight: bold;
    text-align: center;
}

.priceBreaksModalTable td:nth-child(3) {
    text-align: right;
    color: #ED0084;
}

.priceBreaksModalTable tbody tr:hover {
    background-color: #f4f4f4;
}

#priceBreaksAccordion .card {
    border: none;
    border-bottom: 2px solid #F1F4F8;
    border-radius: 0;
}

#priceBreaksModal .modal-dialog {
    max-width: 770px;
}

#priceBreaksAccordion .card-header {
    padding: 0;
    border: none;
    background-color: transparent;
}

#priceBreaksAccordion .card-header button {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #051F59;
    width: 100%;
    text-align: left;
    border-radius: 0;
    border: none;
    display: block;
    text-decoration: none;
    padding: 10px 0 10px 15px;
    /*border-bottom: 2px solid #F1F4F8;*/
    position: relative;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

#priceBreaksAccordion .card-header button:hover {
    color: #ED0084;
    background-color: transparent;
}

#priceBreaksAccordion .card-body {
    padding: 0 0 15px 15px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #4F6699;
}

.accordionPlusIndicator {
    display: inline-block;
    width: 8px;
    height: 1.6px;
    background-color: #ED0084;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.accordionPlusIndicator:after {
    content: '';
    height: 1.6px;
    width: 8px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(90deg);
    -moz-transform: translateY(-50%) rotate(90deg);
    -ms-transform: translateY(-50%) rotate(90deg);
    -o-transform: translateY(-50%) rotate(90deg);
    transform: translateY(-50%) rotate(90deg);
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    background-color: #ED0084;
}

#priceBreaksAccordion .card-header button[aria-expanded="true"] .accordionPlusIndicator:after {
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    background-color: #4F6699;
}

#priceBreaksAccordion .card-header button[aria-expanded="true"] {
    font-weight: bold;
    color: #ED0084;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.priceBreaksAccordionTitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #051F59;
    margin-top: 25px;
    border-bottom: 2px solid #F1F4F8;
    padding-bottom: 10px;
}

#priceBreaksAccordion .card:last-child {
    border-bottom: none;
}

.artworkGuidelinesButton {
    background-color: #051F59;
    box-sizing: border-box;
    font-family: 'Work Sans';
    border-radius: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    border: none;
    width: 182px;
    height: 33px;
    line-height: 19px;
    color: #FFFFFF;
    margin: 25px auto;
    display: block;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.artworkGuidelinesButton:hover {
    background-color: #ED0084;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.afterAddedToCartModalTitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #051F59;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #F1F4F8;
}

.afterAddedToCartModalTitle span {
    font-size: 16px;
    color: #ED0385;
    margin-right: 13px;
    position: relative;
    top: 1px;
}

#afterAddedToCartModal .modal-body {
    padding: 22px 35px 32px 35px;
}

#afterAddedToCartModalSlider {
    display: inline-block;
    width: 198px;
    float: left;
}

.afterAddedToCartModalInfoBox {
    display: inline-block;
    padding-left: 28px;
    width: calc(100% - 198px);
}

#afterAddedToCartModalSlider .image-gallery-slide-wrapper {
    width: 155px;
    height: 183px;
    margin-left: 48px;
}

#afterAddedToCartModalSlider .image-gallery-slide img {
    height: 183px !important;
}

.afterAddedToCartModalTable tr td:first-child {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #BDCBD8;
    width: 75px;
}

.afterAddedToCartModalTable {
    margin-top: 5px;
}

.afterAddedToCartModalTable tr td {
    padding: 8px 0;
    text-align: left;
}

.afterAddedToCartModalTable tr td:last-child {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #051F59;
}

.afterAddedToCartModalTable tr td span {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 10px;
    display: inline-block;
    position: relative;
    top: 3px;
}

.afterAddedToCartModalBodyContainer {
    text-align: center;
}

.afterAddedToCartModalBody {
    display: inline-block;
    margin-bottom: 36px;
}

.afterAddedToCartModalBody .image-gallery-thumbnail img {
    height: 30px;
}

.afterAddedToCartModalBody .image-gallery-thumbnails-wrapper {
    width: 30px;
}

.afterAddedToCartModalBody .image-gallery-thumbnail {
    margin-bottom: 15px;
}

.moveToCartInModal,
.closeAfterAddedToCartModal {
    display: inline-block;
    position: relative;
    height: 53px;
}

.moveToCartInModal {
    font-family: 'Work Sans';
    background-color: #ED0084;
    width: 328px;
    border: none;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 0;
    border-radius: 0;
    text-align: center;

    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.moveToCartInModal:hover {
    background-color: #051F59;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.closeAfterAddedToCartModal {
    border: 1px solid #F00084;
    font-family: 'Work Sans';
    width: 294px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    color: #ED0084;
    text-align: left;
    padding-left: 48px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

#afterAddedToCartModal .icon-close {
    position: absolute;
    right: 21px;
    color: #BDCAD8;
    cursor: pointer;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

#afterAddedToCartModal .icon-close:hover {
    color: #FE259E;
    cursor: pointer;
    transform: rotate(90deg);
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.closeAfterAddedToCartModal:hover {
    background-color: #F00084;
    color: #FFFFFF;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.closeAfterAddedToCartModal:hover span {
    color: #FFFFFF;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.moveToCartInModal span {
    font-size: 18px;
    color: #ffffff;
    position: relative;
    top: 1px;
    margin-right: 17px;
}

.closeAfterAddedToCartModal span {
    position: absolute;
    top: 50%;
    left: 16px;
    font-size: 18px;
    color: #ED0084;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.cartIndicatorModalContainer {
    position: fixed;
    left: 50%;
    top: 0;
    width: 100%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

.cartIndicatorModal {
    position: absolute;
    right: 320px;
    top: 12px;
    height: 45px;
    width: 104px;
    background-color: #ffffff;
    border-radius: 10px;
    margin: 0 -15px;
    padding-top: 9px;
    text-align: center;
    cursor: pointer;
}

.cartIndicatorModal label {
    width: 100%;
    margin-left: -2px;
}

.cartIndicatorModalCircle {
    position: absolute;
    right: -5px;
    top: -5px;
    border-radius: 50%;
    background-color: #ED0084;
    border: 3px solid #8F979C;
    width: 20px;
    height: 20px;
}

.cartHasProductIndicator {
    position: absolute;
    right: -13px;
    top: 0px;
    border-radius: 50%;
    background-color: #ED0084;
    width: 8px;
    height: 8px;
}

.productSizeNotValidBox {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #ED0084;
    margin-top: 15px;
    display: none;
}

.productSizeNotValidBoxActive {
    display: block;
}

.hiddenElement {
    z-index: -9999999;
    position: fixed;
    opacity: 0;
    width: 0;
    height: 0;
}

.errorColorNumberInput {
    border: 2px solid #ED0084 !important;
}

/*.productSizeItem .errorColorNumberInput {*/
/*    line-height: 30px;*/
/*}*/

#onModelGalleryModal .modal-header {
    padding-top: 10px;
}

#onModelGalleryModal .modal-header .icon-close {
    right: 15px;
    top: 13px;
}

#onModelGalleryModal .priceBreaksModalTitle {
    margin-bottom: 0;
}

#onModelGalleryModal .image-gallery-thumbnail {
    width: 75px;
    height: 75px;
    background-color: #fff;
}

#onModelGalleryModal .image-gallery-thumbnail .image-gallery-thumbnail-inner {
    height: 100%;
    width: 100%;
}

.image-gallery-thumbnail img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

#onModelGalleryModal .image-gallery-thumbnail.active {
    border: 1px solid #ED0084 !important;
    box-shadow: 0px 0px 50px #D6DDE7;
}

#onModelGalleryModal .image-gallery-slide img {
    height: 65vh;
    object-fit: contain;
    width: 100%;
    max-height: 675px;
}

#onModelGalleryModal .fullscreen .image-gallery-slide img {
    height: 100vh;
    max-height: 100vh;
}

#onModelGalleryModal .image-gallery-fullscreen-button,
#onModelGalleryModal .image-gallery-play-button {
    background: rgba(158, 158, 158, 0.06);
    border-radius: 0;
}

#onModelGalleryModal .image-gallery-fullscreen-button::before,
#onModelGalleryModal .image-gallery-play-button::before {
    color: rgba(5, 31, 89, 0.5);
    text-shadow: none;
}

#onModelGalleryModal .image-gallery-fullscreen-button:hover::before,
#onModelGalleryModal .image-gallery-play-button:hover::before {
    color: rgba(5, 31, 89, 1);
}

#onModelGalleryModal .fullscreen .image-gallery-fullscreen-button::before,
#onModelGalleryModal .fullscreen .image-gallery-play-button::before {
    color: #ffffff !important;
}

#onModelGalleryModal .image-gallery-left-nav::before,
#onModelGalleryModal .image-gallery-right-nav::before {
    color: #ED0084;
    position: absolute;
    top: 50%;
    left: 0;
    text-shadow: none;
    font-size: 40px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
}

#onModelGalleryModal .image-gallery-left-nav,
#onModelGalleryModal .image-gallery-right-nav {
    padding: 70px 20px;
}

#onModelGalleryModal .image-gallery-left-nav:hover::before,
#onModelGalleryModal .image-gallery-right-nav:hover::before {
    color: #fff !important;
}

#onModelGalleryModal .fullscreen .image-gallery-left-nav,
#onModelGalleryModal .fullscreen .image-gallery-right-nav {
    padding: 100px 30px;
}

.__react_component_tooltip.place-top {
    border: 1px solid rgb(236, 4, 134);
    padding: 2px 10px;
    border-radius: 0;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
}

.__react_component_tooltip.place-top:before,
.__react_component_tooltip.place-top:after {
    opacity: 0;
}

.buttonNotValid {
    opacity: 0.3;
    pointer-events: none;
    cursor: not-allowed;
}

#artworkGuidelinesModal .modal-content {
    padding: 0 30px;
}

#artworkGuidelinesModal .modal-body {
    padding: 0 15px;
}

#artworkGuidelinesModal .priceBreaksModalTitle {
    font-weight: 900;
    font-size: 18px;
    margin-bottom: 36px;
}

#artworkGuidelinesModal .modal-header .icon-close {
    top: 21px;
}

.iUnderstandButtonContainer {
    text-align: center;
    margin-bottom: 26px;
    margin-top: 15px;
}

.iUnderstandButtonContainer button {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #EC0486;
    width: 174px;
    height: 38px;
    border: 1px solid #F20086;
    border-radius: 0;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.iUnderstandButtonContainer button:hover {
    background-color: #F20086;
    color: #ffffff;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.setInkNumberMessageBox {
    left: calc(100% + 10px);
    visibility: hidden;
    opacity: 0;
}

.notValidInc {
    color: #F20086 !important;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.notValidInc .setInkNumberMessageBox {
    opacity: 1;
    visibility: visible;
    left: calc(100% + 20px);
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.productPricePerItemPanel,
.productTotalPricePanel {
    width: 100%;
}

.saveForLaterImageBlock {
    text-align: center;
}

.saveForLaterModalTitle {
    text-align: center;
    width: 100%;
}

.saveForLaterSubTitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #051F59;
    margin-top: 25px;
}

.saveForLaterDescription {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #051F59;
    max-width: 440px;
    margin: 0 auto;
    margin-bottom: 27px;
}

#saveForLaterModal .modal-dialog {
    max-width: 720px;
}

.saveForLaterModalBody {
    padding: 0 60px 40px 60px;
}

.saveForLaterInputBlock {
    width: 100%;
    height: 38px;
    position: relative;
}

.saveForLaterInputBlock input {
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    font-family: 'Work Sans';
    padding: 0 12px;
    font-style: normal;
    font-weight: normal;
    line-height: 14px;
    color: #000000;
}

.copyToClipboardInput {
    background: #EFFDFF;
    border: 1px solid #1ECBDE;
    font-size: 12px !important;
}

.saveUrlToEmailInput {
    background: #ffffff;
    border: 1px solid #EC0486;
    font-size: 16px;
}

.saveForLaterInputsRow .col-6:nth-child(1) {
    padding-left: 0;
    padding-right: 7.5px;
}

.saveForLaterInputsRow .ol-6:nth-child(2) {
    padding-right: 0;
    padding-left: 7.5px;
}

.saveForLaterInputTitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    text-align: center;
    font-size: 14px;
    line-height: 21px;
    color: #051F59;
    margin-bottom: 9px;
}

.copyToClipboardButton {
    width: 105px;
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    background-color: #1ECBDE;
    color: #ffffff;
    border: none;
    line-height: 0;
    height: 80%;
    font-size: 10px;
    font-weight: bold;
}

.copyToClipboardButton:hover {
    background-color: #1ECBDE;
}

.copyToClipboardButton:active {
    background-color: #F20086 !important;
}

.saveUrlToEmailButton {
    width: 70px;
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    background-color: #F20086;
    color: #ffffff;
    border: none;
    line-height: 0;
    height: 80%;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
}

.saveUrlToEmailButton:hover {
    background-color: #F20086;
}

.saveUrlToEmailButton .saveOrderOnEmailLoader {
    display: none;
}

.saveUrlToEmailButton.is-sending .saveOrderOnEmailLoader {
   display: block;
}

.saveUrlToEmailButton.is-sending span {
    display: none;
}

.saveOrderOnEmailLoader {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.saveUrlToEmailButton.sending-done {
    width: calc(100% - 8px);
    /*background-color: #4CAF50;*/
    /*border-color: #4CAF50;*/
    pointer-events: none;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.saveUrlToEmailButton .sv-done {
    display: none;
}

.saveUrlToEmailButton.sending-done .sv-done {
    display: block;
}

.saveUrlToEmailButton.sending-done .sv-send {
    display: none;
}

.copyToClipboardButtonBlock:before {
    content: '';
    position: absolute;
    right: 105px;
    width: 20px;
    top: 50%;
    height: 20px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);

    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#7db9e8+0,effdff+0&0+0,1+65 */
    background: -moz-linear-gradient(left,  rgba(239,253,255,0) 0%, rgba(239,253,255,1) 65%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(239,253,255,0) 0%,rgba(239,253,255,1) 65%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(239,253,255,0) 0%,rgba(239,253,255,1) 65%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00effdff', endColorstr='#effdff',GradientType=1 ); /* IE6-9 */
}

.saveForLaterLoader {
    position: relative;
    height: 67px;
}

.saveForLaterLoader svg {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

#saveForLaterModal .modal-header .icon-close {
    right: 21px;
    top: 21px;
}


/* TESTING */
/*.servicesTypeRadioButtons button:nth-child(1),*/
/*.servicesTypeRadioButtons button:nth-child(2) {*/
/*    visibility: hidden;*/
/*}*/

/*.servicesTypeRadioButtons {*/
/*    margin: 0 0 5px 0;*/
/*}*/







































@media screen and (max-width: 1200px) {

    .productSizeItem input, .productSizeItem span {
        padding-left: 10px;
        font-size: 17px;
    }

    .productSizeItem span {
        padding-left: 0;
    }

    .singleProductAddToCartContainer {
        padding: 8px 15px;
    }

    .totalPriceAfterCalculate {
        font-size: 15px;
        margin-top: 0px;
    }

    .productionCompletedDate {
        font-size: 10px;
        line-height: initial;
    }

    .totalPriceAfterCalculate span {
        /*font-size: 18px;*/
        /*margin-left: 8px;*/
        /*top: 1px;*/
    }

    .singleProductInfoPart {
        padding-right: 15px;
    }

    .singleProductAddToCartContainer .stepCalculationMainButton {
        /*width: 140px;*/
        /*font-size: 13px;*/
    }

    .stepCalculationMainButton span {
        /*font-size: 14px;*/
        /*margin-left: 10px;*/
    }

    .productPricePerOneContainer {
        padding: 20px 0 0 0;
    }

    .productPricePerOneTitle {
        font-size: 13px;
    }

    .productPricePerOne {
        font-size: 15px;
    }

    .priceBreaksButton {
        font-size: 14px;
        width: 110px;
        height: 29px;
    }

    .itemSideUploadedImageName {
        /*max-width: 50px;*/
    }

    .cartIndicatorModal {
        right: 227px;
    }

    .serviceTypeButton {
        width: 150px;
        font-size: 13px;
        padding-left: 38px;
        height: 35px;
    }

    .serviceTypeButton.printingType span {
        font-size: 12px;
        left: 23px;
    }

    .serviceTypeButton.embroideryType span {
        font-size: 18px;
        left: 30px;
    }

    .saveForLaterButton span {
        font-size: 17px;
    }

    .saveForLaterButton {
        font-size: 13px;
    }

    .singleProductSidesOptionsTable .table td:first-child,
    .singleProductSidesOptionsTable .table thead th:first-child {
        width: 95px;
        min-width: 95px;
    }

    .advanced-settings-is-active .itemSideUploadedImageName {
        max-width: 70px;
        font-size: 12px;
    }

    .deleteUploadImageButton {
        margin-left: 0px;
        margin-right: 5px;
    }

    .advancedSettingsButton {
        height: 35px;
        line-height: 35px;
    }

    .singleProductAddToCartContainer .row .col-6:last-child {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .singleProductAddToCartContainer .row .col-6:last-child button {
        margin-top: 0;
    }

    .singleProductAddToCartContainer {
        height: auto;
    }

    .singleProductEditingButtonsBlock {
        width: 100%;
    }

    .volumeSavingsBlock {
        margin-bottom: 5px;
    }

    .productionCompletedDate.desktop-only {
        margin-bottom: 5px;
    }

}


@media screen and (min-width: 992px) and (max-width: 1100px) {

    .singleProductEditingButtonsBlock button {
        font-size: 12px;
    }

    .cancelEditingProductButton {
        width: calc(100% - 60%);
        margin-right: 0;
    }

    .cancelEditingProductButton span {
        font-size: 9px;
        margin-left: 2px;
    }

    .saveProductAfterEditing span {
        font-size: 9px;
    }

}


@media screen and (min-width: 1200px) and (min-height: 740px) and (max-height: 900px) {

    .stepEditRightPart {
        position: fixed;
        right: calc((100% - 1170px)/2);
    }

}


@media screen and (min-width: 992px) and (max-width: 1199px) {

    .singleProductSidesOptionsTable .table td:first-child,
    .singleProductSidesOptionsTable .table thead th:first-child {
        width: 90px;
        min-width: 90px;
        font-size: 13px;
    }

    .singleProductSidesOptionsTable .table thead th {
        font-size: 13px;
    }

    .singleProductSidesOptionsTable .table thead th:nth-child(2) {
        text-align: center;
    }

    .uploadArtworkFromTable {
        font-size: 13px;
        width: 140px;
        margin-right: 8px;
    }

    .garage-select__single-value {
        font-size: 13px;
    }

    .garage-select__single-value {
        padding-left: 12px;
    }

    .artWidthSelect .garage-select__control {
        width: 60px;
        margin: 0 auto;
    }

    .incColorSelect .garage-select__control {
        width: 45px;
    }

    .spr-panel {
        padding: 0px 15px 15px 15px;
    }

    .singleProductInfoPart {
        flex-basis: calc(100% - 400px);
        max-width: calc(100% - 400px);
    }

    .singleProductCanvasPart {
        flex: 0 0 400px;
        max-width: 400px;
    }

    .garage-select__placeholder {
        font-size: 13px;
        padding-left: 10px;
    }

    .spr-panel.productOptionsContainer {
        padding-top: 15px;
    }

    .konvajs-content {
        margin-left: -36px !important;
    }

    .artworkDisclaimer {
        font-size: 12px;
    }

}



@media screen and (max-width: 992px) {

    .mobileVersionContainer .serviceTypeButton {
        width: 50%;
        margin-right: 0 !important;
        border: 1px solid #4F6699;
        color: #4F6699;
        box-shadow: none;
        border-radius: 0;
        background-color: #ffffff;
        font-size: 14px;
    }

    .mobileVersionContainer .serviceTypeButton.activeType {
        background-color: #051F59 !important;
        color: #ffffff !important;
    }

    .servicesTypeRadioButtons button:nth-child(1) {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .servicesTypeRadioButtons button:nth-child(2) {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .mobileVersionContainer .productCanvasPanel,
    .mobileVersionContainer .productSidesPanel {
        width: 100%;
    }

    .stepEditProduct {
        overflow: hidden;
    }

    .mobileVersionContainer .singleProductSidesOptionsTable {
        border-radius: 0;
    }

    .mobileVersionContainer .servicesTypeRadioButtons:after {
        display: none;
    }

    .mobileVersionContainer .singleProductSidesOptionsTable table td:nth-child(2),
    .mobileVersionContainer .singleProductSidesOptionsTable table th:nth-child(2) {
        text-align: center;
    }

    .mobileVersionContainer .singleProductSidesOptionsTable table td:nth-child(3),
    .mobileVersionContainer .singleProductSidesOptionsTable table th:nth-child(3) {
        min-width: auto;
        width: 150px;
    }

    .mobileVersionContainer .singleProductItemSideUploadedImageInfo {
        max-width: 280px;
        margin: 0 auto;
    }

    .mobileVersionContainer .setInkNumberMessageBox,
    .mobileVersionContainer .totalPriceErrorMessage {
        left: 50%;
        top: -70px;
        -webkit-transform: translateX(-50%);
        -moz-transform:    translateX(-50%);
        -ms-transform:     translateX(-50%);
        -o-transform:      translateX(-50%);
        transform:         translateX(-50%);
    }

    .mobileVersionContainer .setInkNumberMessageBox:before,
    .mobileVersionContainer .totalPriceErrorMessage:before {
        left: 50%;
        bottom: -21px;
        top: initial;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #f20086;

        -webkit-transform: translateX(-50%);
        -moz-transform:    translateX(-50%);
        -ms-transform:     translateX(-50%);
        -o-transform:      translateX(-50%);
        transform:         translateX(-50%);
    }

    .mobileVersionContainer .servicesTypeRadioButtons {
        margin-bottom: 20px;
    }

    .mobileVersionContainer .singleProductItemSideUploadedImageInfo > div {
        max-width: 270px;
        margin: 0 auto;
    }

    .mobileVersionContainer .productSizeItem input,
    .mobileVersionContainer .productSizeItem span {
        padding-left: 0;
        height: 40px;
    }

    .mobileVersionContainer .productSizeItem span {
        line-height: 36px;
    }

    .mobileVersionContainer .productPricePerOneTitle {
        font-size: 15px;
    }

    .mobileVersionContainer .productPricePerOne {
        font-size: 20px;
    }

    .mobileVersionContainer .productSizeItem label {
        font-size: 14px !important;
    }

    .mobileVersionContainer .totalPriceAfterCalculate {
        font-size: 17px;
    }

    .mobileVersionContainer .totalPriceBlock {
        font-size: 18px;
    }

    .mobileVersionContainer .productionCompletedDate {
        font-size: 12px;
        margin-top: 2px;
    }

    .mobileVersionContainer .singleProductEditingButtonsBlock button {
        width: calc(50% - 2.5px);
        font-size: 14px;
    }

    .mobileVersionContainer .embroideryPriceBlock .stepCalculationMainButton span {
        font-size: 10px;
    }


    /* PRODUCT SIDES SLIDER START */

    .slick-dots {
        bottom: -19px !important;
    }

    .slick-dots li {
        margin: 0 !important;
        width: 15px !important;
    }

    .slick-dots li button:before {
        color: #979797;
    }

    .slick-dots li.slick-active button:before {
        color: #21386B;
    }

    .productSidesSliderItem {
        height: 500px;
        position: relative;
        overflow: hidden;
    }

    .productSidesSliderItem img {
        width: auto;
        position: absolute;
        left: 50%;
        top: 50%;
        height: 650px;
        width: auto;
        /*max-height: 90%;*/
        -webkit-transform: translate(-50%, -50%);
        -moz-transform:    translate(-50%, -50%);
        -ms-transform:     translate(-50%, -50%);
        -o-transform:      translate(-50%, -50%);
        transform:         translate(-50%, -50%);
    }

    .showOnModelModalButtonMob {
        font-family: Work Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        float: right;
        color: #ED0084;
        position: relative;
        display: inline-block;
        z-index: 2;
        padding: 5px 0;
        margin-top: -5px;
    }

    .showOnModelModalButtonMobText {
        text-decoration: underline;
    }

    .showOnModelModalButtonMob .icon-arrow-right {
        font-size: 8px;
        font-weight: bold;
        margin-left: 9px;
        position: relative;
        top: 0px;
    }

    .productSidesSliderPanel {
        position: relative;
    }

    .mobileVersionContainer .saveForLaterButton {
        color: #4F6699;
        background-color: #ffffff !important;
        transition: all .3s;
        box-shadow: 0px 0px 20px #D6DDE7;
        border-radius: 40px;
        left: 0;
        padding: 0 10px;
        height: 33px;
        line-height: 33px;
    }

    .mobileVersionContainer .saveForLaterButton:before {
        display: none;
    }

    .saveForLaterInputsRow .col-6 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 !important;
    }

    .saveForLaterInputsRow .col-6:nth-child(1) {
        margin-bottom: 30px;
    }

    /* PRODUCT SIDES SLIDER END */

    .cartIndicatorModal {
        right: 116px;
        top: 8px;
    }



    .mobileVersionContainer .advancedSettingsButton {
        display: none;
    }

    .mobileVersionContainer .artworkDisclaimer {
        font-size: 12px;
    }





    .productColorsPanel,
    .productQuantityAndSizePanel {
        padding: 35px 0;
    }

}


@media screen and (min-width: 767px) {

    .productionCompletedDate.desktop-only {
        display: block;
    }

    .productionCompletedDate.mobile-only {
        display: none;
    }

    .backToStartStepButton {
        display: none;
    }

}


@media screen and (max-width: 767px) {

    .productionCompletedDate.desktop-only {
        display: none;
    }

    .productionCompletedDate.mobile-only {
        display: block;
        margin-top: 15px;
        text-align: center;
    }

    .productionCompletedDate .productionCompletedDateValue {
        font-weight: bold;
        text-decoration: none;
    }

    .productionCompletedDate .productionCompletedDateButton {
        color: #ED0084;
        margin-left: 2px;
    }

    .productionCompletedDate .productionCompletedDateButton:hover {
        background-color: #ED0084;
        color: #ffffff;
    }

    .volumeSavingsBlock {
        margin-left: 0;
    }

    .deliveringModalTypeItem {
        width: 100%;
        padding-top: 0;
        height: auto;
        margin: 0 0 10px 0;
        overflow: hidden;

        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .deliveringModalTypeItemIcon {
        position: relative;

        -ms-flex: 0 0 95px;
        flex: 0 0 95px;
        max-width: 95px;
    }

    .deliveringModalTypeItemBody {
        width: calc(100% - 95px);
        padding-left: 0;

        /*-ms-flex: 0 0 95px;*/
        flex: 0 0 calc(100% - 95px);
        max-width: calc(100% - 95px);
    }

    .deliveringModalTypeItemName {
        font-size: 16px;
        line-height: 28px;
        margin-top: 5px;
        margin-bottom: 0;
    }

    .deliveringModalTypeItemInfo {
        line-height: 20px;
    }

    .deliveringModalTypeItemInfo strong:last-child {
        /*float: left;*/
        display: inline-block;
        margin-left: 5px;
    }

    .deliveringModalTypeItemFooter {
        padding: 8px 0;
        font-size: 14px;
        line-height: 16px;
        text-align: left;
    }

    .deliveringModalTypeItemIcon span {
        top: 50% !important;

        -webkit-transform: translateY(-50%);
        -moz-transform:    translateY(-50%);
        -ms-transform:     translateY(-50%);
        -o-transform:      translateY(-50%);
        transform:         translateY(-50%);
    }

    .deliveringModalTypeItemIcon .icon-rabbit {
        left: -45px;
    }

    .deliveringModalTypeItemIcon .icon-cheetah {
        left: -70px;
    }

    .deliveringModalTypeItemIcon .icon-eagle {
        left: -50px;
    }

    .deliveringModalTypeResultName {
        width: 100%;
        display: block;
        margin: 0;
    }

    .changeDeliveringTypeButton {
        width: 100%;
        display: block;
        margin-left: 0 !important;
        margin-top: 10px;
    }

    #deliveringTypeModal .modal-footer {
        display: block;
        padding: 0px 15px 30px 15px;
    }

    #deliveringTypeModal .modal-header {
        padding-top: 40px;
    }

    #deliveringTypeModal .modal-header .icon-close {
        top: 14px;
        right: 14px;
    }

    .deliveringModalDescription {
        width: 100%;
    }

    .deliveringModalWave {
        display: none;
    }

    .deliveringModalTitle {
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 0px;
    }

    .deliveringModalDescription {
        font-size: 12px;
        line-height: 18px;
    }

    .deliveringModalTypeList {
        margin-top: 30px;
    }

    .singleProductAddToCartContainer .col-6 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .singleProductAddToCartContainer .col-6:nth-child(1) {
        margin-bottom: 8px;
        text-align: center;
    }

    .singleProductAddToCartContainer {
        padding: 8px 15px 10px 15px;
    }

    .singleProductAddToCartContainer .addToCart {
        width: 100%;
    }

    .backToStartStepButton {
        display: inline-block;
        width: 77px;
        height: 34px;
        line-height: 34px;
        line-height: 34px;
        margin-left: -20px;
        padding-left: 20px;
        position: relative;

        font-family: Work Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #051F59;
        margin-right: 20px;

        background-color: #FFFFFF;
    }

    .backToStartStepButton:before {
        content: '';
        position: absolute;
        right: 0;
        z-index: -1;
        top: 0;
        height: 100%;
        width: 500%;
        box-shadow: 0px 0px 50px #D6DDE7;
        background-color: #ffffff;
    }

    .backToStartStepButton span {
        transform: rotate(-180deg);
        display: inline-block;
        font-size: 8px;
        font-weight: bold;
        margin-right: 3px;
        top: -2px;
        position: relative;
    }

    .stepBody {
        padding-top: 45px;
    }

    .stepEditProduct,
    .stepSelectProduct {
        padding-top: 15px;
    }

    #afterAddedToCartModalSlider,
    .afterAddedToCartModalInfoBox {
        width: 100%;
        float: none;
        padding-left: 0;
    }

    .moveToCartInModal {
        width: 100%;
    }

    .afterAddedToCartModalTitle {
        font-weight: 900;
        font-size: 18px;
        line-height: 21px;
    }

    #afterAddedToCartModalSlider .image-gallery,
    .afterAddedToCartModalTable {
        width: 250px;
        margin: 0 auto;
    }

    #afterAddedToCartModalSlider .image-gallery-slide img {
        height: 205px !important;
    }

    #afterAddedToCartModalSlider .image-gallery-slide-wrapper {
        width: 180px;
        height: 205px;
        margin-left: 60px;
    }

    .previewProductSliderContainer .image-gallery-thumbnails-wrapper {
        width: 46px;
    }

    .previewProductSliderContainer .image-gallery-thumbnail img {
        height: 46px !important;
    }

    #afterAddedToCartModal .modal-body {
        padding: 35px 17px 17px 17px;
    }

    #afterAddedToCartModal .icon-close,
    .modal-header .icon-close {
        top: 14px;
        right: 17px;
    }

    .afterAddedToCartModalBody {
        margin-bottom: 15px;
    }

    #afterAddedToCartModal,
    #saveForLaterModal,
    #priceBreaksModal,
    #deliveringTypeModal,
    #onModelGalleryModal {
        padding: 15px !important;
    }

    #afterAddedToCartModal .modal-dialog,
    #saveForLaterModal .modal-dialog,
    #priceBreaksModal .modal-dialog,
    #deliveringTypeModal .modal-dialog,
    #onModelGalleryModal .modal-dialog {
        margin: 0;
    }

    .priceBreaksModalTitle, .guidelinesModalTitle {
        font-size: 18px;
        color: #051F59;
        margin-bottom: 8px;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    #onModelGalleryModal .modal-header {
        padding-top: 35px;
    }

    #onModelGalleryModal .priceBreaksModalTitle {
        text-align: center;
        display: block;
        width: 100%;
        margin-bottom: 5px;
    }

    .singleProductSubTitle.productEditingMode {
        font-size: 14px;
        margin-bottom: 5px;
    }




    .cartIndicatorModal {
        display: none;
    }

}


@media screen and (min-width: 460px) {}


@media screen and (max-width: 460px) {

    .mobileVersionContainer .singleProductSidesOptionsTable table td:nth-child(1),
    .mobileVersionContainer .singleProductSidesOptionsTable table th:nth-child(1) {
        max-width: 80px;
        width: 80px;
        font-weight: bold;
    }


    .mobileVersionContainer .uploadArtworkFromTable {
        width: 87px;
        color: #ED0084;
        border-color: #ED0084;
        font-weight: bold;
    }
    
    .mobileVersionContainer .uploadArtworkFromTable .desktop-only {
        display: none;
    }

    .mobileVersionContainer .singleProductSidesOptionsTable {
        padding: 0;
        background-color: transparent;
        box-shadow: none;
    }

    .uploadArtworkFromTable .icon-arrow-down {
        opacity: 1;
        margin-right: 6px !important;
        margin-left: 0px !important;
    }

    .mobileVersionContainer .singleProductSidesOptionsTable table td:nth-child(3),
    .mobileVersionContainer .singleProductSidesOptionsTable table th:nth-child(3) {
        min-width: auto;
        width: 85px;
    }

    .itemSideUploadedImageName {
        max-width: 65px;
        margin-left: 8px;
        margin-right: 0;
    }

    .filestackUploadProgressBarSizeValue {
        font-size: 9px;
    }

    .currentUploadSizeValue {
        margin-right: 3px;
    }

    .finalUploadSizeValue {
        margin-left: 3px;
    }

    .mobileVersionContainer .singleProductItemSideUploadedImageInfo > div {
        max-width: 135px;
    }

    .singleProductSubTitle {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 17px;
    }

    .singleProductTitle {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 7px;
    }

    .productSizeItem {
        margin-right: 6%;
        width: 20.49% !important;
    }

    .serviceTypeButton.printingType span {
        font-size: 10px;
        left: 20px;
    }

    .serviceTypeButton.embroideryType span {
        font-size: 16px;
        left: 23px;
    }

    .embroideryType {
        padding-left: 25px;
    }

    .productPricePerOneTitle {
        font-size: 14px;
        line-height: 21px;
        margin-right: 10px;
    }

    .productPricePerOne {
        font-size: 24px;
        line-height: 28px;
        margin-right: 10px;
        top: 2px;
        position: relative;
    }

    .productPricePerOneContainer {
        padding: 0;
    }

    .priceBreaksButton {
        top: -1px;
        border: none;
        text-decoration: underline;
        font-size: 16px;
        line-height: 19px;
        /*background-color: #ED0084;*/
    }

    .singleProductAddToCartContainer {
        height: auto;
    }

    .totalPriceBlock {
        display: block;
        margin-left: 0;
    }

    .volumeSavingsBlock {
        padding: 0;
        text-decoration: underline;
        border: none;
    }

    .additionalPercentageForTotalPriceBySpeed {
        width: 100%;
    }

    .productionCompletedDate.mobile-only {
        margin-top: 5px;
    }

    .totalPriceBlock {
        display: inline-block;
        margin-left: 5px;
    }

    .saveForLaterModalBody {
        padding: 0 0px 40px 0px;
    }

    .saveForLaterInputsRow .col-6:nth-child(1) {
        margin-bottom: 15px;
    }

    .saveForLaterImageBlock img {
        max-width: 150px;
    }

    .saveForLaterInputsRow button {
        width: 105px;
    }


    /* PRODUCT SIDES SLIDER START */

    .productSidesSliderItem {
        height: 370px;
    }

    .productSidesSliderItem img {
        height: 460px;
    }

    /* PRODUCT SIDES SLIDER END */

}


@media screen and (max-width: 360px) {

    .stepEditProduct .mobileVersionContainer .col-12 {
        padding: 0;
    }

    .mobileVersionContainer .serviceTypeButton span {
        display: none;
    }

    .mobileVersionContainer .serviceTypeButton {
        padding: 0 !important;
    }

    .saveForLaterInputBlock input {
        font-size: 13px;
    }

    .mobileVersionContainer .priceBreaksButton {
        font-size: 15px;
        width: auto;
    }

    #priceBreaksModal .modal-body {
        padding: 0 15px;
    }

    #priceBreaksModal .modal-header {
        padding: 35px 15px 0 15px;
    }

    .mobileVersionContainer .additionalPercentageForTotalPriceBySpeed {
        font-size: 11px;
    }

    .afterAddedToCartModalTitle {
        font-size: 16px;
    }

}

